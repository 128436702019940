import { values } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { DataSource } from '../../types/status-source-type';

type Props = {
  onRemoveTag?: (names: string[]) => void;
  name?: string;
  inputRef?: React.ClassAttributes<HTMLSelectElement>['ref'];
  defaultValue?: string;
  classNameColumn?: string;
  classNameSelect?: string;
  options?: DataSource;
  onChange?: (value: string) => void;
  emptySelectionLabel?: string;
  disableAllValue?: boolean;
  customValue?: (value: any) => any;
  placeholder?: string;
  disabled?: boolean;
};

export function Select(props: Props) {
  const { formatMessage } = useIntl();

  return (
    <div className={`column ${props.classNameColumn}`}>
      <div className="columns">
        <div className="column">
          <div className={`select is-small ${props.classNameSelect}`}>
            <select
              disabled={props.disabled}
              name={props.name}
              defaultValue={props.defaultValue}
              ref={props.inputRef}
              onChange={(e) =>
                props.onChange?.(props.customValue ? props.customValue(e.target.value) : e.target.value)
              }>
              {!props.disableAllValue && (
                <option key="" value="">
                  {props.emptySelectionLabel
                    ? formatMessage({ id: props.emptySelectionLabel })
                    : formatMessage({ id: 'All' })}
                </option>
              )}
              {props.options &&
                values(props.options).map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label
                      ? !!item.valueOfTranslation
                        ? formatMessage({ id: item.label }, { ...item?.valueOfTranslation })
                        : formatMessage({ id: item.label })
                      : ''}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}
