import { ValueOfTranslation } from './ms-core/types/status-source-type';

export interface INavigation {
  items: IMenuSection[];
}

export interface IMenuItem {
  name: string;
  valueOfTranslation?: ValueOfTranslation;
  url: string;
  icon: string;
  topUp?: string;
}

export interface IMenuGroup {
  name: string;
  valueOfTranslation?: ValueOfTranslation;
  url: string;
  icon: string;
  subMenus: IMenuItem[];
  topUp?: string;
}

export interface IMenuSection {
  name: string;
  valueOfTranslation?: ValueOfTranslation;
  title: boolean;
  child: (IMenuItem | IMenuGroup)[];
}

const menus: IMenuSection[] = [];

const uiConfigs: IMenuSection = {
  name: 'MANAGEMENT SYSTEM',
  title: true,
  child: [
    {
      name: 'Users',
      url: '/accounts',
      icon: 'mdi-account-group',
    },
    {
      name: 'Checking history',
      url: '/checking-history',
      icon: 'mdi-microsoft-access',
    },
  ],
};

menus.push(uiConfigs);

const navigation = {
  items: menus,
};

export default navigation;
