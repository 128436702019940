import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { GetContentModalHtml } from '../../../../../components/form-detail/FormGetContentHtmlModal';
import { GetContentModal } from '../../../../../components/form-detail/FormGetContentModal';
import { ModalUploadFile } from '../../../../../components/modal-upload-image';
import Tabs from '../../../../../components/tab/Tab';
import TabPane from '../../../../../components/tab/TabPanel/tab-panel';
import { LANGUAGE } from '../../../../../hook/use-lang';
import { DataSource } from '../../../../../ms-core/types/status-source-type';
import { getSectionAdminUIConfig } from '../../../../../services/common/admin-builders';
import { getLangUIConfig } from '../../../../../services/common/website-builders';
import { AddSection } from '../../component/form-add-section';
import { FormControlContent } from '../../component/form-control-content';
import { Select } from '../../component/select';
import { FormControlLang } from '../../../../sections/src/components/form-control-lang';
import { IAdminSectionProp } from './admin-section.type';
import './admin-section.style.scss';

export const AdminSectionPage: React.FC<IAdminSectionProp> = () => {
  const [lang, setLang] = useState<string>(LANGUAGE.EN);

  const [languageTypeSource, setLanguageTypeSource] = useState<DataSource>();

  const [languageTypeList, setLanguageTypeList] = useState<Record<string, any>[]>([]);

  const [sectionsType, setSectionsType] = useState<string[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const changeDataFollowLang = useCallback(async (lang: string) => {
    setLang(lang as LANGUAGE);
  }, []);

  const fetchDataLang = useCallback(() => {
    setLoading(true);

    getLangUIConfig()
      .then((resLang) => {
        setLanguageTypeList(resLang);
        const languageTypeSourceData: DataSource = {};
        resLang?.forEach((item: Record<string, any>) => {
          languageTypeSourceData[item?.language] = {
            label: item.name,
            value: item?.language,
            color: 'white',
          };
        });
        setLanguageTypeSource(languageTypeSourceData);
      })
      .finally(() => setLoading(false));
  }, []);

  const fetchSectionAdminUIConfig = useCallback(() => {
    getSectionAdminUIConfig(lang).then((res) => {
      setSectionsType(res);
    });
  }, [lang]);

  useEffect(() => {
    fetchDataLang();
    fetchSectionAdminUIConfig();
  }, [fetchDataLang, fetchSectionAdminUIConfig]);

  return (
    <div className="admin-section section">
      <div className="columns is-desktop">
        <div className="column">
          <div className="card has-height-medium">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-alpha-a-circle" />
                  </span>
                  <span>
                    <FormattedMessage id={'General'} />
                  </span>
                </p>
                <a className="card-header-icon">
                  <button className="is-small button is-ghost mr-0">
                    {!!languageTypeSource && (
                      <Select
                        onChangeData={changeDataFollowLang}
                        defaultValue={lang}
                        name="lang"
                        options={languageTypeSource}
                        disableAllValue
                      />
                    )}
                  </button>
                  <AddSection lang={lang} refreshData={fetchSectionAdminUIConfig} />
                  <ModalUploadFile folderName="ui-config" isHasAccessPublic />
                  <GetContentModalHtml />
                  <GetContentModal />
                </a>
              </header>
              <div className="card-content pt-0">
                <div className="content">
                  <div className="admin-section">
                    <Tabs>
                      <TabPane name="General" key={0}>
                        <FormControlLang data={languageTypeList} isLoading={loading} fetchData={fetchDataLang} />
                      </TabPane>
                      {sectionsType.map((item, key) => {
                        return (
                          <TabPane name={item} key={key + 1}>
                            <FormControlContent lang={lang} title={item} type={item} />
                          </TabPane>
                        );
                      })}
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
