import { keys } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { DataSource, IDataSourSelect } from '../../types/status-source-type';
import Select, { Theme } from 'react-select';
import { CSSObject } from '@emotion/serialize';

type Props = {
  name: string;
  inputRef?: React.ClassAttributes<HTMLSelectElement>['ref'];
  classNameColumn?: string;
  options?: DataSource;
  onChange?: (value: string) => void;
  emptySelectionLabel?: string;
  disableAllValue?: boolean;
  setValue?: (name: string, value: any) => void;
  defaultValue?: string | string[];
};

export function MultipleSelect(props: Props) {
  const { formatMessage } = useIntl();
  const { options, name, emptySelectionLabel, inputRef, onChange, defaultValue } = props;
  const [value, setValue] = useState<IDataSourSelect[]>();

  const combineValue = (data: { value: string; label: string }[]) => {
    const value = data.reduce((p, c) => `${p},${c.value}`, '').substr(1);
    props.setValue?.(name, value);
    onChange?.(value);
    setValue(data);
  };

  const convertOption = (options: DataSource): IDataSourSelect[] => {
    const data: IDataSourSelect[] = [];
    keys(options).forEach((item: any) =>
      data.push({
        label:
          item &&
          formatMessage(
            { id: options?.[item].label },
            options?.[item]?.valueOfTranslation ? options?.[item]?.valueOfTranslation : {},
          ),
        value: item,
      }),
    );
    return data;
  };

  useEffect(() => {
    if (defaultValue && options) {
      const filterData = convertOption?.(options)?.filter(({ value }) =>
        typeof defaultValue === 'string'
          ? defaultValue.split(',').includes?.(value as string)
          : defaultValue.includes?.(value as string),
      );
      setValue(filterData);
    }
  }, []);

  useEffect(() => {
    if (!defaultValue) setValue([]);
  }, [defaultValue]);

  return (
    <div className={`column ${props.classNameColumn}`}>
      <div className="columns">
        <div className="column is-narrow">
          <input type="hidden" name={name} ref={inputRef as any} />
          <div style={{ minWidth: 300, maxWidth: 400 }}>
            {options && (
              <Select
                value={value}
                isMulti
                onChange={(e) => combineValue(e as { value: string; label: string }[])}
                options={convertOption(options)}
                placeholder={formatMessage({ id: emptySelectionLabel })}
                theme={themeSmall}
                styles={customStylesSmall as any}
                menuPosition={'fixed'}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export const themeSmall = (theme: Theme) => ({
  ...theme,
  spacing: {
    ...theme.spacing,
    controlHeight: 30,
    baseUnit: 2,
  },
});

export const customStylesSmall = {
  valueContainer: (provided: CSSObject) => ({
    ...provided,
    padding: '0 6px',
  }),
  option: (provided: CSSObject) => ({
    ...provided,
    padding: '0 6px',
    fontSize: '0.75rem',
  }),
  control: (styles: CSSObject) => ({ ...styles, fontSize: '0.75rem' }),
};
