import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DataType } from '../models/ms-type';
import { ValueOfTranslation } from '../types/status-source-type';

interface Props {
  field: string;
  name: string;
  customize?: React.ReactNode;
  className?: string;
  dataType?: DataType;
  sortable?: boolean;
  sortOrder?: 'desc' | 'asc';
  onSortChange?: (sort: string) => void;
  abbrTitle?: string;
  sortName?: string;
  valueOfTranslation?: ValueOfTranslation;
}

function MsCellHead(props: Props) {
  const { formatMessage } = useIntl();

  const onClick = (): void => {
    if (props.sortable && props.onSortChange) {
      props.onSortChange(
        `${props.sortName ? props.sortName : props.field},${props.sortOrder === 'asc' ? 'desc' : 'asc'}`,
      );
    }
  };

  let align: 'right' | 'left' | 'centered';
  switch (props.dataType) {
    case 'money':
    case 'number':
      align = 'right';
      break;
    case 'tag':
    case 'select':
    case 'status':
    case 'image':
    case 'imageService':
      align = 'centered';
      break;
    default:
      align = 'left';
      break;
  }

  return (
    <th
      className={`is-sortable has-text-${align} ${props.className}`}
      key={props.field}
      style={{ whiteSpace: 'nowrap' }}
      onClick={onClick}>
      <div className="th-wrap">
        {props.customize ? (
          props.customize
        ) : props.abbrTitle ? (
          <abbr title={props.name ? formatMessage({ id: props.name }) : ''}>{props.abbrTitle}</abbr>
        ) : props.name ? (
          props.valueOfTranslation ? (
            <FormattedMessage id={props?.name ?? ''} values={props?.valueOfTranslation} />
          ) : (
            <FormattedMessage id={props?.name ?? ''} />
          )
        ) : (
          ''
        )}
        {props.sortable && (
          <span className="icon is-mall">
            <i
              className={`mdi mdi-${
                !props.sortOrder ? 'menu-swap' : props.sortOrder === 'asc' ? 'menu-up' : 'menu-down'
              }`}
            />
          </span>
        )}
      </div>
    </th>
  );
}

export default MsCellHead;
