import moment from 'moment';
import React, { useState } from 'react';
import { useController } from 'react-hook-form';
import InputCcy from '../../components/input-currency/InputCurrency';
import { DataSourceItem } from '../types/status-source-type';
import Calendar from 'rc-calendar';
import GGTimePicker from './gg-time-picker';
import { FormattedMessage, useIntl } from 'react-intl';

type CFC<T> = React.FC<Parameters<typeof useController>[0] & T>;

/**
 * * INPUT
 */
type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

export const Input: CFC<InputProps> = ({ name, rules, defaultValue, control, ...rest }) => {
  const { formatMessage } = useIntl();

  const {
    field: { ref, ...props },
  } = useController({ name, rules, defaultValue: defaultValue ?? '', control });
  return (
    <input {...rest} {...props} ref={ref} placeholder={rest.placeholder && formatMessage({ id: rest.placeholder })} />
  );
};

export const HiddenInput: CFC<InputProps> = ({ name, rules, defaultValue, control, ...rest }) => {
  const {
    field: { ref, ...props },
  } = useController({ name, rules, defaultValue: defaultValue ?? '', control });
  return <input {...rest} {...props} ref={ref} type="hidden" />;
};

/**
 * * TEXTAREA
 */

type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement>;

export const Textarea: CFC<TextareaProps> = ({ name, rules, defaultValue, control, ...rest }) => {
  const {
    field: { ref, ...props },
  } = useController({ name, rules, defaultValue: defaultValue ?? '', control });
  return <textarea {...rest} {...props} ref={ref} />;
};

/**
 * * INPUT CCY
 */

export const InputCcyControl: CFC<React.ComponentProps<typeof InputCcy>> = ({
  name,
  rules,
  defaultValue,
  control,
  ...rest
}) => {
  const {
    field: { onChange, ...props },
  } = useController({ name, rules, defaultValue: defaultValue ?? '', control });
  return <InputCcy {...rest} {...props} onValueChange={onChange} />;
};

/**
 * * SELECT
 */
export type SelectItem = Omit<DataSourceItem, 'color'>;
type SelectProps = React.SelectHTMLAttributes<HTMLSelectElement> & {
  items: SelectItem[];
  isLoading?: boolean;
  translation?: boolean;
};

export const Select: CFC<SelectProps> = ({
  name,
  rules,
  defaultValue,
  control,
  items,
  className,
  isLoading,
  translation = true,
  ...rest
}) => {
  const {
    field: { onChange, ref, value },
  } = useController({ name, rules, defaultValue: defaultValue ?? items[0]?.value ?? '', control });
  const { formatMessage } = useIntl();

  return (
    <div className={`select${isLoading ? ' is-loading' : ''} ${className ?? ''}`}>
      <select {...rest} value={value} name={name} ref={ref} onChange={onChange}>
        {items.map((item) => (
          <option key={item.value as any} value={item.value as any}>
            {item.label && translation ? formatMessage({ id: item.label }) : item.label}
          </option>
        ))}
      </select>
    </div>
  );
};

/**
 * * TIME PICKER
 */
type DatePickerProps = {
  disabled?: boolean;
  placeholder?: string;
  extInputClass?: string;
  time?: boolean;
  defaultValue?: string;
  format?: string;
  style?: React.CSSProperties;
};
export const DatePicker: CFC<DatePickerProps> = ({ name, rules, defaultValue, control, ...rest }) => {
  const {
    field: { onChange, ref, value },
  } = useController({ name, rules, defaultValue: defaultValue ?? moment(), control });
  const { formatMessage } = useIntl();

  const onChangeDate: Calendar['props']['onChange'] = (date) => {
    date && onChange(date.format());
  };

  const idDropdown = `dropdown-${name}`;

  return (
    <div className={`dropdown ${rest.disabled ? '' : 'is-hoverable'}`}>
      <div className="dropdown-trigger has-text-centered">
        <input key={'_' + name} name={name} ref={ref} className="is-hidden" />
        <input
          className={`input has-text-centered ${rest.extInputClass ?? ''}`}
          aria-haspopup="true"
          aria-controls={idDropdown}
          readOnly
          placeholder={
            rest.placeholder
              ? formatMessage({ id: rest.placeholder })
              : `--  ${formatMessage({ id: 'Date selection' })} --`
          }
          value={
            value ? (rest.format ? moment(value).format(rest.format) : moment(value).format('DD/MM/YYYY HH:mm:ss')) : ''
          }
        />
      </div>
      <div className="dropdown-menu" id={idDropdown} role="menu">
        <div className="dropdown-content">
          <div className="dropdown-item" style={{ ...rest.style }}>
            <Calendar
              timePicker={rest.time ? GGTimePicker : undefined}
              defaultValue={moment(defaultValue)}
              format={rest.format ?? 'DD/MM/YYYY HH:mm:ss'}
              onChange={onChangeDate}
              showDateInput={false}
              showOk={false}
              value={moment(value)}
              style={{
                width: '100%',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const ImagePicker: CFC<InputProps> = ({ name, rules, defaultValue, control, disabled, ...rest }) => {
  const {
    field: { onChange, value },
  } = useController({ name, rules, defaultValue: defaultValue ?? null, control });
  const [file, setFile] = useState<File>();

  const fr = new FileReader();
  fr.onload = (e) => onChange(e.target?.result);

  const onDelete = () => {
    onChange(null);
    setFile(undefined);
  };

  return (
    <>
      <div className={`file has-name${disabled ? ' is-hidden' : ''}`}>
        <label className="file-label">
          <input
            className="file-input"
            type="file"
            onChange={(e) => {
              const file = e.target.files?.[0];
              if (file == null) return;
              setFile(file);
              fr.readAsDataURL(file);
            }}
            disabled={disabled}
          />
          <span className="file-cta">
            <span className="file-icon">
              <i className="fas fa-upload" />
            </span>
            <span className="file-label">
              <FormattedMessage id="Choose a file…" />
            </span>
          </span>
          <span className="file-name">{file?.name ?? <FormattedMessage id="No file chose" />}</span>
        </label>
      </div>
      {value && (
        <figure className="image mt-2 has-text-centered" style={{ maxWidth: 300 }}>
          <img src={value} alt="" />
          {!disabled && <button className="delete is-medium mr-0" onClick={onDelete} />}
        </figure>
      )}
    </>
  );
};
