import React, { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { JSONEditorControl } from '../../../../../components/ace-editor/ace-editor.component';
import { useConfirm } from '../../../../../ms-core/hook/use-confirm';
import { showToast } from '../../../../../ms-core/utils/toast';
import { getUIConfigById, updateUIConfigById } from '../../../../../services';

type Props = {
  lang: string;
  platform: string;
  title?: string;
  type?: string;
};

export const FormControlContent = memo((props: Props) => {
  const { lang, type = 'home-content', title = '', platform = '' } = props;

  const { control, handleSubmit, reset, watch } = useForm({ shouldUnregister: false });

  const { showConfirm } = useConfirm();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = async (lang: string, platform?: string) => {
    setIsLoading(true);
    setTimeout(() => {
      getUIConfigById(lang, type, platform)
        .then((res) => {
          reset({ content: res });
        })
        .catch(() => reset({ content: {} }))
        .finally(() => setIsLoading(false));
    }, 100);
  };

  useEffect(() => {
    if (!lang) return;

    fetchData(lang, platform);
  }, [lang, platform]);

  const onSubmit = (values: Record<string, any>) => {
    updateUIConfigById(values.content, lang, type, platform).then(() => {
      showToast.success('Update Resource Success');
      fetchData(lang, platform);
    });
  };

  const onConfirmSubmit = (values: Record<string, any>) => {
    showConfirm('You have changes made. Would you like to save current progress?', 'Accept', {
      onConfirm: () => onSubmit(values),
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onConfirmSubmit)}>
        <div className={`field is-horizontal p-5 is-border`}>
          <div className="field-label is-normal has-text-left pb-4	">
            <div className="label">{isLoading ? <Skeleton count={1} /> : <FormattedMessage id={title} />}</div>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <div
                  className={`info-description 
                 `}>
                  <div className="field pt-2">
                    <label className="label">
                      <div className="level">
                        <div className="level-left">
                          <div className="level-item">
                            {isLoading ? <Skeleton count={1} /> : <FormattedMessage id="Content" />}
                          </div>
                        </div>
                        <div className="level-right">
                          <div className="level-item">
                            {isLoading ? (
                              <Skeleton count={1} />
                            ) : (
                              <button onClick={() => fetchData(lang)} type="button" className="button is-small ml-2">
                                <span className="icon">
                                  <i className="mdi mdi-refresh default" />
                                </span>
                                <span>
                                  <FormattedMessage id="Refresh data" />
                                </span>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </label>
                    <div className="control">
                      {isLoading ? (
                        <Skeleton width="100%" height="50vh" count={1} />
                      ) : (
                        <JSONEditorControl defaultValue={watch('content')} name="content" control={control} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="field">
          <div className="field-label is-normal" />
          <div className="field-body">
            <div className="field">
              {isLoading ? (
                <Skeleton count={1} />
              ) : (
                <button type="submit" onClick={onConfirmSubmit} className="button is-primary">
                  <span>
                    <FormattedMessage id="Submit" />
                  </span>
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
});
