import React, { memo, useEffect, useState } from 'react';
import { LANGUAGE } from '../../../../../hook/use-lang';
import { DataSource } from '../../../../../ms-core/types/status-source-type';
import { getAdminUIConfigById, getLangUIConfig, getSectionUIConfig } from '../../../../../services';
import { FormattedMessage } from 'react-intl';
import { GetContentModalHtml } from '../../../../../components/form-detail/FormGetContentHtmlModal';
import { GetContentModal } from '../../../../../components/form-detail/FormGetContentModal';
import { ModalUploadFile } from '../../../../../components/modal-upload-image';
import { Select } from '../../components';
import { AddSection } from '../../components/form-add-section';
import Tabs from '../../../../../components/tab/Tab';
import TabPane from '../../../../../components/tab/TabPanel/tab-panel';
import { FormControlLang } from '../../components/form-control-lang';
import { FormControlContent } from '../../components/form-control-content-website-builder';
import './index.scss';

const PlatformConfiguration = memo(() => {
  const [lang, setLang] = useState<string>(LANGUAGE.EN);

  const [platform, setPlatform] = useState<string>('');

  const [languageTypeSource, setLanguageTypeSource] = useState<DataSource>();

  const [platformTypeSource, setPlatformTypeSource] = useState<DataSource>();

  const [languageTypeList, setLanguageTypeList] = useState<Record<string, any>[]>([]);

  const [platformTypeList, setPlatformTypeList] = useState<Record<string, any>[]>([]);

  const [sectionsType, setSectionsType] = useState<string[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const changeDataFollowLang = async (lang: string) => {
    setLang(lang as LANGUAGE);
  };

  const changeDataFollowPlatform = async (lang: string) => {
    setPlatform(lang);
  };

  const fetchDataLang = () => {
    setLoading(true);

    getLangUIConfig()
      .then((resLang) => {
        setLanguageTypeList(resLang);
        const languageTypeSourceData: DataSource = {};
        resLang?.forEach((item: Record<string, any>) => {
          languageTypeSourceData[item?.language] = {
            label: item.name,
            value: item?.language,
            color: 'white',
          };
        });
        setLanguageTypeSource(languageTypeSourceData);
      })
      .finally(() => setLoading(false));
  };

  const fetchDataPlatform = () => {
    setLoading(true);

    getAdminUIConfigById('en', 'platforms')
      .then((res) => {
        setPlatformTypeList(res?.platforms ?? []);
        const tmpDataSource: DataSource = {
          '': {
            label: 'Default',
            value: '',
            color: 'white',
          },
        };
        res?.platforms?.forEach((item: Record<string, any>) => {
          tmpDataSource[item?.platform] = {
            label: item.name,
            value: item?.platform,
            color: 'white',
          };
        });
        res?.platforms && setPlatformTypeSource(tmpDataSource);
      })
      .finally(() => setLoading(false));
  };

  const fetchSectionUIConfig = () => {
    setLoading(true);
    getSectionUIConfig(lang, platform)
      .then((res) => {
        setSectionsType(res);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchDataLang();
    fetchDataPlatform();
  }, []);

  useEffect(() => {
    fetchSectionUIConfig();
  }, [platform, lang]);

  return (
    <div className={`platform-configuration section ${loading ? 'is-loading' : ''}`}>
      <div className="columns is-desktop">
        <div className="column">
          <div className="card has-height-medium">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-file-cog" />
                  </span>
                  <span>
                    <FormattedMessage id={'Website Builders'} />
                  </span>
                </p>
                <a className="card-header-icon">
                  <button className="is-small button is-ghost mr-0">
                    {!!languageTypeSource && (
                      <Select
                        onChangeData={changeDataFollowLang}
                        defaultValue={lang}
                        name="lang"
                        options={languageTypeSource}
                        disableAllValue
                      />
                    )}
                    <div className="mr-3" />
                    {!!platformTypeSource && (
                      <Select
                        onChangeData={changeDataFollowPlatform}
                        defaultValue={platform}
                        name="platform"
                        options={platformTypeSource}
                        disableAllValue
                      />
                    )}
                  </button>
                  <AddSection
                    platform={platform}
                    lang={lang}
                    refreshData={(section) => {
                      setSectionsType((prev) => [...prev, section]);
                      setLang(lang);
                      setPlatform('');
                    }}
                  />
                  <ModalUploadFile folderName="ui-config" isHasAccessPublic />
                  <GetContentModalHtml />
                  <GetContentModal />
                  {/* <button onClick={() => fetchSectionUIConfig()} type="button" className="button is-small ml-2">
                  <span className="icon">
                    <i className="mdi mdi-refresh default" />
                  </span>
                  <span>
                    <FormattedMessage id="Refresh section" />
                  </span>
                </button> */}
                </a>
              </header>
              <div className="card-content pt-0">
                <div className="content">
                  <div className="platform-configuration">
                    <Tabs>
                      <TabPane name="General" key={0}>
                        <FormControlLang data={languageTypeList} isLoading={loading} fetchData={fetchDataLang} />
                      </TabPane>
                      {sectionsType.map((item, key) => {
                        return (
                          <TabPane name={item} key={key + 1}>
                            <FormControlContent platform={platform} lang={lang} title={item} type={item} />
                          </TabPane>
                        );
                      })}
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PlatformConfiguration;
