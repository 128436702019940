import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import './index.scss';

type Props = {
  children: any;
  onSelect?: (e: number) => void;
  hiddenTabName?: boolean;
};

const Tabs = (props: Props) => {
  const { children, onSelect } = props;

  const location = useLocation();

  const [selected, setSelected] = useState<number>(0);

  const handleChange = (index: number) => {
    setSelected(index);
    onSelect?.(index);
  };

  useEffect(() => {
    setSelected(0);
  }, [location]);

  if (!children) return null;

  return (
    <div className="tabs-wrapper">
      <div className={`tabs is-medium ${props.hiddenTabName ? 'display-none' : ''}`}>
        <ul className="ml-0 tabs__flex-wrap-item">
          {_.isArray(children) &&
            children.flat()?.map(
              (
                elem: {
                  props: { name: string };
                },
                index: number,
              ) => {
                const style = index === selected ? 'is-active' : '';
                return (
                  <li key={index} className={style} onClick={() => handleChange(index)}>
                    <a> {elem?.props?.name && <FormattedMessage id={elem?.props?.name} />}</a>
                  </li>
                );
              },
            )}
          {!_.isArray(children) && (
            <li key={children?.key} className="is-active" onClick={() => handleChange(children?.key)}>
              <a> {children?.props?.name && <FormattedMessage id={children?.props?.name} />}</a>
            </li>
          )}
        </ul>
      </div>
      <div className="tab-content">
        <div className="tab-child">{_.isArray(children) ? children.flat()[selected] : children}</div>
      </div>
    </div>
  );
};

export default Tabs;
