/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
class Toast {
  private static instance: Toast;

  public static getInstance(): Toast {
    if (!Toast.instance) {
      Toast.instance = new Toast();
    }
    return Toast.instance;
  }
  errorCode(code: number | string, value?: any) {
    toast.error(<FormattedMessage id={`error.${code}`} values={value} />);
  }
  success(text: string, value?: any) {
    toast.success(<FormattedMessage id={text} values={value} />);
  }
  info(text: string, value?: any) {
    toast.info(<FormattedMessage id={text} values={value} />);
  }
  warning(text: string, value?: any) {
    toast.warning(<FormattedMessage id={text} values={value} />);
  }
  dark(text: string, value?: any) {
    toast.dark(<FormattedMessage id={text} values={value} />);
  }
  warn(text: string, value?: any) {
    toast.warn(<FormattedMessage id={text} values={value} />);
  }
  error(text: string, value?: any) {
    toast.error(<FormattedMessage id={text} values={value} />);
  }
}
export const showToast = Toast.getInstance();
