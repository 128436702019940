import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import { useModal } from '../../ms-core/hook/use-modal';
import { showToast } from '../../ms-core/utils/toast';
import { getGallery } from '../../services/common/admin-gallery';
import { TYPE_FILE, MAX_SIZE_OF_FILE } from '../../utils/secrets';
import { ImgServer } from '../image-server';
import { ScrollBarControl } from '../scroll-bar-control';
import './select-method-upload-photos.scss';

type ModalUploadFileServicerWithSuggestedPhotosButtonProps = {
  onChange?: (e?: File | string) => void;
  hiddenCopy?: boolean;
  isHasCard?: boolean;
  defaultValue?: string;
  type?: string[];
  size?: number;
};

export function ModalUploadFileServicerWithSuggestedPhotosButton({
  onChange,
  hiddenCopy,
  isHasCard,
  defaultValue,
  type,
  size,
}: ModalUploadFileServicerWithSuggestedPhotosButtonProps) {
  const { showModal, onHide } = useModal();

  const [previewFile, setPreviewFile] = useState<string | undefined>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onClick = () => {
    showModal(
      <ModalUploadFileServicerWithSuggestedPhotosForm
        isHasCard={isHasCard}
        onHide={onHide}
        onChange={onChange}
        hiddenCopy={hiddenCopy}
        defaultValue={defaultValue}
        type={type}
        size={size}
      />,
    );
  };

  const getBase64 = () => {
    if (!defaultValue) return;
    if (typeof defaultValue === 'string') {
      setPreviewFile(defaultValue);
    } else {
      setIsLoading(true);
      const reader = new FileReader();
      reader.readAsDataURL(defaultValue);
      reader.onload = () => {
        setPreviewFile(reader.result as string);
        setIsLoading(false);
      };
    }
  };

  useEffect(() => {
    if (defaultValue) {
      getBase64();
    } else {
      onChange?.(defaultValue);
      setPreviewFile(defaultValue);
    }
  }, [defaultValue]);

  return (
    <div>
      <ScrollBarControl id="upload" style={{ maxHeight: '350px' }}>
        {previewFile && <ImgServer isLoading={isLoading} src={previewFile} className="image-banner" alt="" />}
      </ScrollBarControl>
      <button type="button" className="button is-small is-light" onClick={onClick}>
        <span className="icon">
          <i className="mdi mdi-cloud-upload default" />
        </span>
        <span>
          <FormattedMessage id="Upload Image" />
        </span>
      </button>
    </div>
  );
}

type ModalUploadFileServicerWithSuggestedPhotosButtonForm = {
  onChange?: (e?: File | string) => void;
  hiddenCopy?: boolean;
  onHide?: () => void;
  isHasCard?: boolean;
  defaultValue?: string;
  type?: string[];
  size?: number;
};

export function ModalUploadFileServicerWithSuggestedPhotosForm({
  onChange,
  hiddenCopy,
  onHide,
  defaultValue,
  isHasCard = true,
  type = TYPE_FILE,
  size = MAX_SIZE_OF_FILE,
}: ModalUploadFileServicerWithSuggestedPhotosButtonForm) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [photos, setPhotos] = useState<Record<string, any>[]>([]);

  const [pagination, setPagination] = useState<Record<string, any>>({ number: 1, size: 5 });

  const [previewFile, setPreviewFile] = useState<string | ArrayBuffer | undefined>();

  const [fileUpload, setFileUpload] = useState<File | string | undefined>();

  const [expanded, setExpanded] = useState(true);

  const toggleCardState = () => {
    setExpanded(!expanded);
  };

  const fetchData = async (number: number) => {
    setPagination((prev) => {
      return { ...prev, number: prev.number + number };
    });

    await getGallery({ page: pagination.number + number, size: pagination.size })
      .then((res) => {
        setPhotos((prev) => [...prev, ...res?.gallery]);
        setPagination(res.page);
      })
      .catch(() => setPhotos([]));
  };

  const getBase64 = (file: File) => {
    setFileUpload(file);

    setIsLoading(true);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setPreviewFile(reader.result as ArrayBuffer);
      setIsLoading(false);
    };
  };

  const delayTimeOut = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  };

  useEffect(() => {
    fetchData(0);
  }, []);

  useEffect(() => {
    setPreviewFile(defaultValue);
    defaultValue && setFileUpload(defaultValue);
  }, [defaultValue]);

  return (
    <>
      {!previewFile && (
        <div className="card select-method-upload-photos-button-form">
          {isHasCard && (
            <header className="card-header">
              <p className="card-header-title p-3">
                <FormattedMessage id="Upload picture" />
              </p>
            </header>
          )}

          <div className="card-content p-0">
            <div className="column ">
              <div className="column is-full">
                <div className="field">
                  <div className="control">
                    <div className="level">
                      <div className="level-left">
                        <div className="level-item">
                          <div className="file is-small has-name">
                            <label className="file-label">
                              <input
                                type="file"
                                className="file-input"
                                accept={type.join(',')}
                                onChange={(e) => {
                                  const file = e.target.files?.[0];
                                  if (!!file && type.includes(file.type) && file.size <= size) {
                                    onChange?.(file);
                                    getBase64(file);
                                  } else {
                                    showToast.warn('Invalid uploaded image format');
                                  }
                                }}
                              />
                              <span className="file-cta">
                                <span className="file-icon">
                                  <i className="fas fa-upload" />
                                </span>
                                <span className="file-label">
                                  <FormattedMessage id="Choose a file…" />
                                </span>
                              </span>
                              <span className="file-name">
                                {(fileUpload instanceof File && fileUpload?.name) ?? (
                                  <FormattedMessage id="No file chose" />
                                )}
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                      {defaultValue && (
                        <button
                          disabled={defaultValue ? false : true}
                          type="button"
                          className="button is-small is-primary"
                          onClick={() => {
                            setPreviewFile(defaultValue);
                            defaultValue && setFileUpload(defaultValue);
                          }}>
                          <span className="icon">
                            <i className="mdi mdi-square-edit-outline" />
                          </span>
                          <span>
                            <FormattedMessage id="Preview" />
                          </span>
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                <div className="suggested-photos field">
                  <label className="label">
                    <div className="level">
                      <div className="level-left">
                        <div className="level-item">{/* <FormattedMessage id="Suggested" /> */}</div>
                      </div>
                      <div className="level-right">
                        <div className="level-item">
                          <span className="icon is-clickable" onClick={toggleCardState}>
                            <i className={`mdi mdi-${!expanded ? 'chevron-down' : 'chevron-up'}`} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </label>

                  {expanded && (
                    <div className="control suggested-photos-control">
                      {isLoading ? (
                        <Skeleton width="100%" height="300px" count={1} />
                      ) : (
                        <div className="suggested-photos-warper">
                          {photos.map((photo) => {
                            return (
                              <div
                                onClick={() => {
                                  setPreviewFile(photo.src);
                                  setFileUpload(photo.src);
                                  onChange?.(photo.src);
                                }}
                                className="suggested-photos-item"
                                key={photo.src}>
                                <img src={photo.src} alt={photo.title ?? ''} />
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {pagination.number !== pagination.totalPages && (
                        <button
                          type="button"
                          className="button is-small is-primary"
                          onClick={() => {
                            fetchData(1);
                          }}>
                          <span className="icon">
                            <i className="mdi mdi-refresh" />
                          </span>
                          <span>
                            <FormattedMessage id="See more" />
                          </span>
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {previewFile && (
        <div className="card select-method-upload-photos-button-form">
          {isHasCard && (
            <header className="card-header">
              <p className="card-header-title p-3">
                <FormattedMessage id="Upload picture" />
              </p>
            </header>
          )}
          <div className="card-content p-0">
            <div className="column ">
              <div className="column is-full">
                <div className="field">
                  <div className="control">
                    {typeof previewFile === 'string' && (
                      <>
                        <ScrollBarControl id="upload" style={{ maxHeight: '350px' }}>
                          <ImgServer isLoading={isLoading} src={previewFile} className="image-banner" alt="" />
                        </ScrollBarControl>
                      </>
                    )}
                    {(previewFile === 'empty' || previewFile === undefined || isLoading) && (
                      <Skeleton width="100%" height="400px" count={1} />
                    )}
                  </div>
                </div>
                <div className="field is-grouped is-grouped-centered">
                  <div className="control">
                    <div
                      className={`button  is-small is-primary ${isLoading ? ' is-loading' : ''}`}
                      onClick={() => {
                        delayTimeOut();
                        setPreviewFile(undefined);
                      }}>
                      <FormattedMessage id="Update" />
                    </div>
                  </div>
                  <div className="control">
                    <div
                      className={`button  is-small is-danger ${isLoading ? ' is-loading' : ''}`}
                      onClick={() => {
                        setPreviewFile('empty');
                        onChange?.('');
                        delayTimeOut();
                      }}>
                      <FormattedMessage id="Delete" />
                    </div>
                  </div>
                  {isHasCard && (
                    <div className="control">
                      <div
                        className={`button  is-small is-info ${isLoading ? ' is-loading' : ''}`}
                        onClick={() => {
                          onChange?.(previewFile === 'empty' ? '' : fileUpload);
                          onHide?.();
                          delayTimeOut();
                        }}>
                        <FormattedMessage id="Save" />
                        &nbsp;
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
