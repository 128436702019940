import React from 'react';
import { FormattedMessage } from 'react-intl';

export function CustomMenuItemNav(props: any) {
  const { icon, name, valueOfTranslation } = props.item;

  return (
    <>
      <div className="media">
        <div className="media-left mr-0">
          <span className="icon">
            <i className={`mdi ${icon}`} />
          </span>
        </div>
        <div className="media-content">
          <p className="">
            {valueOfTranslation ? (
              <FormattedMessage id={name} values={valueOfTranslation} />
            ) : (
              <FormattedMessage id={name} />
            )}
          </p>
        </div>
      </div>
    </>
  );
}

export default CustomMenuItemNav;
