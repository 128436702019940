import React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  /**
   * Return to type of the notification.
   */
  type: 'error' | 'success' | null;
  /**
   * Gets or sets a text message that is displayed for user as the reason why
   * this notification come out.
   */
  message: string;
}

export const FormStatus: React.FunctionComponent<Props> = (props: Props) => (
  <div
    className={`notification${props.type === 'error' ? ' is-danger' : ''}${
      props.type === 'success' ? ' is-success' : ''
    }`}>
    <FormattedMessage id={props.message} />
  </div>
);
