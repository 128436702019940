/**
 *
 * ScrollBarControl
 *
 */
import React from 'react';
import './ScrollBarControl.scss';
interface Props {
  id: string;
  style?: React.CSSProperties;
  children: any;
  className?: string;
}

export function ScrollBarControl(props: Props) {
  return (
    <div className={`scroll-wrapper ${props.className ? props.className : ''}`} id={props.id} style={props.style}>
      {props.children}
    </div>
  );
}
