import React from 'react';
import moment from 'moment';
import 'moment-timezone';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Route, Router } from './ms-core/utils/routers';
import { ProvideMsRouter } from './ms-core/utils/routers';
import { store } from './redux/storage';
import { RouteProps, Switch } from 'react-router';
import { ProvideAuth } from './ms-core/utils/auth';
import { ConfirmProvider } from './ms-core/hook/use-confirm';
import { ModalProvider } from './ms-core/hook/use-modal';
import { useLang } from './hook/use-lang';
import { IntlProvider } from 'react-intl';
import { LoginPage } from './pages';
import { Layout } from './layouts';
import { DEFAULT_TIMEZONE } from './utils/date-format';
import { IAppProps } from './app.type';
import 'react-toastify/dist/ReactToastify.css';
import './assets/scss/main.scss';
import './app.style.scss';

moment.tz.setDefault(DEFAULT_TIMEZONE.VN);

export const App: React.FC<IAppProps> = (props) => {
  const render = (props: RouteProps) => <Layout {...props} />;

  const lang = useLang();

  return (
    <IntlProvider messages={props.messages[lang.local]} locale={lang.local}>
      <ProvideAuth>
        <ProvideMsRouter>
          <ConfirmProvider>
            <Provider store={store}>
              <Router>
                <ModalProvider>
                  <Switch>
                    <Route exact path="/auth/signin" component={LoginPage} />
                    <Route path="/" render={render} />
                  </Switch>
                  <ToastContainer autoClose={2000} />
                </ModalProvider>
              </Router>
            </Provider>
          </ConfirmProvider>
        </ProvideMsRouter>
      </ProvideAuth>
    </IntlProvider>
  );
};
