import React, { memo, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ModalUploadFile } from '../../../../../components/modal-upload-image';
import { LANGUAGE } from '../../../../../hook/use-lang';
import { DataSource } from '../../../../../ms-core/types/status-source-type';
import { getLangUIConfig } from '../../../../../services/common/website-builders';
import { Select } from '../../components/select';
import { FormControlContentWelcomePopup } from '../../components/welcome-popup-website-builder';

const PlatformConfiguration = memo(() => {
  const [lang, setLang] = useState<string>(LANGUAGE.EN);
  const [languageTypeSource, setLanguageTypeSource] = useState<DataSource>();
  const [languageTypeList, setLanguageTypeList] = useState<Record<string, any>[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const changeDataFollowLang = async (lang: string) => {
    setLang(lang as LANGUAGE);
  };

  const fetchDataLang = () => {
    setLoading(true);

    getLangUIConfig()
      .then((resLang) => {
        setLanguageTypeList(resLang);
        const languageTypeSourceData: DataSource = {};
        resLang?.forEach((item: Record<string, any>) => {
          languageTypeSourceData[item?.language] = {
            label: item.name,
            value: item?.language,
            color: 'white',
          };
        });
        setLanguageTypeSource(languageTypeSourceData);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchDataLang();
  }, []);

  return (
    <div className={`platform-configuration section`}>
      <div className="columns is-desktop">
        <div className="column">
          <div className="card has-height-medium">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-content-save-cog" />
                  </span>
                  <span>
                    <FormattedMessage id="Popup app" />
                  </span>
                </p>
                <a className="card-header-icon">
                  <button className="is-small button is-ghost mr-0">
                    {!!languageTypeSource && (
                      <Select
                        onChangeData={changeDataFollowLang}
                        defaultValue={lang}
                        name="lang"
                        options={languageTypeSource}
                        disableAllValue
                      />
                    )}
                  </button>
                  <ModalUploadFile folderName="ui-config" isHasAccessPublic />
                </a>
              </header>
              <div className="card-content pt-0">
                <div className="content">
                  <div className="platform-configuration">
                    {/* <Tabs>
                      <TabPane name="welcome-popup">
                      </TabPane>
                    </Tabs> */}
                    <FormControlContentWelcomePopup lang={lang} title="Welcome Popup" type="welcome-popup" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PlatformConfiguration;
