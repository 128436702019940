import { debounce, keys } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import Select, { MultiValueProps, Theme } from 'react-select';
import { CSSObject } from '@emotion/serialize';
import { DataSource, IDataSourSelect } from '../../ms-core/types/status-source-type';
import { useController } from 'react-hook-form';
import { MultiValueRemoveProps } from 'react-select/src/components/MultiValue';

type CFC = React.FC<
  Parameters<typeof useController>[0] & {
    name?: string;
    classNameColumn?: string;
    options?: DataSource;
    onChange?: (value: string) => void;
    placeholder?: string;
    disableAllValue?: boolean;
    setValue?: (name: string, value: any) => void;
    defaultValue?: string | string[] | number[];
    defaultValueFixed?: string[];
    label?: string;
    errors?: boolean;
    notTranslation?: boolean;
    style?: React.CSSProperties;
    disabled?: boolean;
    className?: string;
    handleChange?: (e: string | string[]) => void;
    isGetArray?: boolean;
    isNotCreate?: boolean;
    isSmall?: boolean;
    notCheckResetValue?: boolean;
  }
>;

export const MultipleSelectWithDataSource: CFC = ({
  options,
  name,
  control,
  rules,
  defaultValue,
  label,
  errors,
  handleChange,
  disabled,
  style,
  notTranslation = false,
  placeholder,
  isGetArray,
  isNotCreate = false,
  defaultValueFixed,
  isSmall,
  classNameColumn,
  notCheckResetValue = false,
}) => {
  const customStylesNormal = {
    valueContainer: (provided: CSSObject): CSSObject => ({
      ...provided,
      padding: '0 6px',
      overflowY: 'auto',
      maxHeight: '400px',
    }),
    option: (provided: CSSObject): CSSObject => ({
      ...provided,
      padding: '8px 6px',
    }),
    control: (base: CSSObject) => ({
      ...base,
      borderColor: errors ? 'red' : 'hsl(0,0%,80%)',
      boxShadow: errors ? 'red' : 'hsl(0,0%,80%)',
    }),
    multiValue: (base: CSSObject, state: MultiValueProps<any, any>): CSSObject => {
      return defaultValueFixed?.includes(state.data.value) ? { ...base, backgroundColor: 'gray' } : base;
    },
    multiValueLabel: (base: CSSObject, state: MultiValueProps<any, any>): CSSObject => {
      return defaultValueFixed?.includes(state.data.value)
        ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
        : base;
    },
    multiValueRemove: (base: CSSObject, state: MultiValueRemoveProps<any, any>): CSSObject => {
      return defaultValueFixed?.includes(state.data.value) ? { ...base, display: 'none' } : base;
    },
  };

  const {
    field: { onChange },
  } = useController({ name, rules, control });

  const { formatMessage } = useIntl();
  const [values, setValue] = useState<IDataSourSelect[]>();
  const [optionsData, setOptionsData] = useState<IDataSourSelect[]>([]);

  const combineValue = (data: { value: string; label: string }[]) => {
    const value = data.reduce((p, c) => `${p},${c.value}`, '').substr(1);
    onChange?.(isGetArray ? value?.split(',') : value);
    handleChange?.(isGetArray ? value?.split(',') : value);
    setValue(data);
  };

  const convertOption = (options: DataSource): IDataSourSelect[] => {
    const data: IDataSourSelect[] = [];
    keys(options).forEach((item: any) =>
      data.push({
        label:
          item && !notTranslation
            ? formatMessage(
                { id: options?.[item].label },
                options?.[item]?.valueOfTranslation ? options?.[item]?.valueOfTranslation : {},
              )
            : options?.[item].label,
        value: `${item}`,
      }),
    );
    return data;
  };

  useEffect(() => {
    if (defaultValue && options) {
      const filterData = convertOption?.(options)?.filter(({ value }) =>
        typeof defaultValue === 'string'
          ? defaultValue.split(',').includes?.(value as string)
          : defaultValue.map(String).includes?.(`${value}`),
      );
      setValue(filterData);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (!defaultValue && notCheckResetValue === false) setValue([]);
  }, [defaultValue]);

  const search = useRef(
    debounce((value) => {
      options && setOptionsData([...convertOption(options), { value: value, label: value }]);
    }, 300),
  );

  const handleOnchange = (value: string) => {
    search.current(value);
  };

  useEffect(() => {
    if (options) {
      setOptionsData(convertOption(options));
    }
  }, [options]);

  return (
    <>
      {isSmall ? (
        <div className={`column ${classNameColumn}`}>
          <div className="columns">
            <div className="column is-narrow">
              <div style={{ minWidth: 300, maxWidth: 400 }}>
                {options && (
                  <Select
                    closeMenuOnSelect={false}
                    name={name}
                    value={values}
                    isMulti
                    onChange={(e) => combineValue(e as { value: string; label: string }[])}
                    options={optionsData}
                    placeholder={formatMessage({ id: placeholder })}
                    styles={customStylesSmall as any}
                    onInputChange={(option) => {
                      !isNotCreate && handleOnchange(option);
                    }}
                    isDisabled={disabled}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    menuPosition={'fixed'}
                    isClearable={!values?.some((i) => defaultValueFixed?.includes(i?.value as string))}
                    theme={themeSmall}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="selection-control" style={style}>
          {label && (
            <label className="label">
              {label} <b className="has-text-danger">*</b>
            </label>
          )}
          <div className="field">
            <div>
              {options && (
                <Select
                  closeMenuOnSelect={false}
                  name={name}
                  value={values}
                  isMulti
                  onChange={(e) => combineValue(e as { value: string; label: string }[])}
                  options={optionsData}
                  placeholder={formatMessage({ id: placeholder })}
                  styles={customStylesNormal as any}
                  menuPosition={'fixed'}
                  onInputChange={(option) => {
                    !isNotCreate && handleOnchange(option);
                  }}
                  isDisabled={disabled}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  isClearable={!values?.some((i) => defaultValueFixed?.includes(i?.value as string))}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const themeSmall = (theme: Theme) => ({
  ...theme,
  spacing: {
    ...theme.spacing,
    controlHeight: 30,
    baseUnit: 2,
  },
});

const customStylesSmall = {
  valueContainer: (provided: CSSObject) => ({
    ...provided,
    padding: '0 6px',
  }),
  option: (provided: CSSObject) => ({
    ...provided,
    padding: '0 6px',
    fontSize: '0.75rem',
  }),
  control: (styles: CSSObject) => ({ ...styles, fontSize: '0.75rem' }),
  menuPortal: (base: CSSObject) => ({ ...base, zIndex: 9999 }),
};
