import React, { MouseEvent } from 'react';
import { useIntl } from 'react-intl';

type Props = {
  onRemoveTag?: (names: string[]) => void;
  name?: string;
  inputRef?: React.ClassAttributes<HTMLInputElement>['ref'];
  defaultValue?: string;
  placeholder?: string;
  classNameColumn?: string;
  classNameInput?: string;
  type?: string;
  onEnter?: (value: string) => void;
};

export default function Input(props: Props) {
  const { formatMessage } = useIntl();

  const reset = (e: MouseEvent) => {
    e.preventDefault();
    if (props.name) {
      props.onRemoveTag?.([props.name]);
    }
  };

  return (
    <div className={`column ${props.classNameColumn ?? ''}`}>
      <div className="columns">
        <div className="column is-narrow">
          <div className="control has-icons-right">
            <input
              name={props.name}
              ref={props.inputRef}
              className={`input is-small ${props.classNameInput ?? ''}`}
              type={props.type ?? 'text'}
              placeholder={props.placeholder ? formatMessage({ id: props.placeholder }) : ''}
              defaultValue={props.defaultValue}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  props.onEnter?.((e?.target as Record<string, any>)?.value);
                }
              }}
            />
            <span className="icon is-small is-right">
              <button className="delete is-small" onClick={reset} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
