/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from 'react';
import CreatableSelect from 'react-select/creatable';
import { DataSource, IDataSourSelect } from '../../ms-core/types/status-source-type';
import { CSSObject } from '@emotion/serialize';
import { FormattedMessage, useIntl } from 'react-intl';
import { useController } from 'react-hook-form';
import { debounce, keys } from 'lodash';

type CFC = React.FC<
  Parameters<typeof useController>[0] & {
    label?: string;
    name: string;
    options: DataSource;
    defaultValue?: string | number | boolean | unknown;
    placeholder?: string;
    className?: string;
    errors?: boolean;
    handleChange?: (e: string) => void;
    eventInput?: (e: string) => void;
    disabled?: boolean;
    isClearable?: boolean;
    style?: React.CSSProperties;
    errorsMessage?: string;
    textHelp?: string;
  }
>;

const CustomCreatableSelect: CFC = ({
  options,
  name,
  control,
  rules,
  placeholder,
  defaultValue,
  label,
  errors,
  handleChange,
  eventInput,
  disabled,
  isClearable,
  style,
  errorsMessage,
  textHelp,
}) => {
  const { formatMessage } = useIntl();

  const customStylesNormal = {
    valueContainer: (provided: CSSObject): CSSObject => ({
      ...provided,
      padding: '0 6px',
    }),
    option: (provided: CSSObject): CSSObject => ({
      ...provided,
      padding: '8px 6px',
    }),
    control: (base: CSSObject) => ({
      ...base,
      borderColor: errors ? 'red' : 'hsl(0,0%,80%)',
      boxShadow: errors ? 'red' : 'hsl(0,0%,80%)',
    }),
    menuPortal: (base: CSSObject) => ({ ...base, zIndex: 9999 }),
  };
  const {
    field: { onChange },
  } = useController({ name, rules, control });
  const convertOption = (options: DataSource): IDataSourSelect[] => {
    const data: IDataSourSelect[] = [];

    options &&
      keys(options).forEach((item: any) =>
        data.push({
          label: item && formatMessage({ id: options?.[item].label }),
          value: item,
        }),
      );
    !!defaultValue &&
      data.filter((o) => `${o.value}` === `${defaultValue}`).length === 0 &&
      data.unshift({
        label: defaultValue as any,
        value: defaultValue,
      });
    return data;
  };
  const search = useRef(
    debounce((value) => {
      eventInput?.(value);
    }, 300),
  );
  const handleOnchange = (value: string) => {
    search.current(value);
  };
  return (
    <div className={`selection-control `} style={style}>
      {label && (
        <label className="label">
          {label} <b className="has-text-danger">*</b>
        </label>
      )}
      <div className={`field`}>
        <CreatableSelect
          className="is-fullwidth"
          defaultValue={
            convertOption(options).filter((option) => `${option.value}` === `${defaultValue}`) ?? {
              label: defaultValue,
              value: defaultValue,
            }
          }
          onChange={(option) => {
            onChange(option?.value);
            handleChange?.(option?.value as string);
          }}
          onInputChange={(option) => {
            handleOnchange(option);
          }}
          options={convertOption(options)}
          placeholder={placeholder ? formatMessage({ id: placeholder }) : ''}
          styles={customStylesNormal as any}
          menuPosition={'fixed'}
          isDisabled={disabled}
          isClearable={isClearable}
        />
        {errorsMessage && <p className="help has-text-danger">{<FormattedMessage id={errorsMessage} />}</p>}
        {textHelp && <p className="help">{<FormattedMessage id={textHelp} />}</p>}
      </div>
    </div>
  );
};

export default CustomCreatableSelect;
