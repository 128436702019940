import _ from 'lodash';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import FormControl from '../../../../../../../../../components/form-detail/FormControl';
import { useModal } from '../../../../../../../../../ms-core/hook/use-modal';
import { showToast } from '../../../../../../../../../ms-core/utils/toast';
import { arrayMove, fGenerateUniqueID } from '../../../../../../../../../utils/helper';
import { IDetailFormProps, IDetailProps } from './detail.type';

export const DetailButton: React.FC<IDetailProps> = (props) => {
  const { items, item, isCreate, conf, response, nameKey, configs, onUpdateData, isLoading } = props;

  const { showModal, onHide } = useModal();

  const onClick = useCallback(() => {
    showModal(
      <UpdateCoreData
        conf={conf}
        item={item}
        items={items}
        onHide={onHide}
        isCreate={isCreate}
        response={response}
        nameKey={nameKey}
        configs={configs}
        isLoading={isLoading}
        onUpdateData={(v) => onUpdateData(v, onHide)}
      />,
      false,
    );
  }, [conf, configs, isCreate, isLoading, item, items, nameKey, onHide, onUpdateData, response, showModal]);

  return (
    <>
      <button className={`${isCreate ? 'is-small' : ''} button is-primary`} onClick={onClick}>
        <span className="icon">
          <i className={`mdi ${isCreate ? '' : 'mdi-18px'} mdi-${isCreate ? 'plus' : 'square-edit-outline'}  `} />
        </span>
        {isCreate && (
          <span>
            <FormattedMessage id="Create" />
          </span>
        )}
      </button>
    </>
  );
};

export const UpdateCoreData: React.FC<IDetailFormProps> = ({
  item,
  isCreate,
  onHide,
  conf,
  items,
  response,
  nameKey,
  onUpdateData,
  isLoading,
}) => {
  const onSubmit = useCallback(
    (values: Record<string, any>) => {
      if (isCreate) {
        if (items.map((i) => `${i.id}`).includes(`${values.id}`)) {
          showToast.error('Invalid id');
        } else {
          const tmpData = {};
          _.set(tmpData, [nameKey], [{ id: values ? values.id : fGenerateUniqueID(), ...values }, ...items]);
          onUpdateData(tmpData, onHide);
        }
      } else {
        if (`${values.id}` !== `${item?.id}` || !item?.id) {
          showToast.error('Invalid id');
        } else {
          const tmpData = {};
          const oldIndex = items.findIndex((i) => i.id === item.id);
          _.set(
            tmpData,
            [nameKey],
            arrayMove([{ ...item, ...values }, ...items.filter((i) => `${i.id}` !== `${item?.id}`)], 0, oldIndex),
          );
          onUpdateData(tmpData, onHide);
        }
      }
    },
    [isCreate, item, items, nameKey, onHide, onUpdateData],
  );

  return (
    <FormControl
      isLoading={isLoading}
      title={isCreate ? 'Create' : 'Update'}
      conf={
        isCreate
          ? [
              {
                field: 'id',
                name: 'Id',
                required: true,
                type: 'id',
              },
              ...conf,
            ]
          : [
              {
                field: 'id',
                name: 'Id',
                readonly: true,
                type: 'id',
              },
              ...conf,
            ]
      }
      defaultValues={isCreate ? {} : item}
      onSubmitData={onSubmit}
      closeModal={onHide}
    />
  );
};
