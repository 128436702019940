const auth = '/auth';
const account = '/account';
const common = '/common';
const cbtcdn = '/cbtcdn';

export const API_ENDPOINT = {
  LOGIN: auth + '/login',
  ME: auth + '/accounts/me/admin-info',
  CHANGE_PASSWORD_ADMIN: auth + '/password/update',
  ACCOUNTS: account + '/all',
  ACCOUNT_ID: auth + '/accounts/getAccountId',
  ACCOUNT_BY_ID: auth + '/accounts/retrieveUserAccount',

  COMMON: common + '/storage',
  ADMIN_UP_LOAD: cbtcdn + '/upload',

  UI_CONFIG_BUILDER: common + '/ui-config',
  ADMIN_UI_CONFIG_BUILDER: common + '/admin/ui-config',

  ADMINS: auth + '/admins',
  FORCE_LOG_OUT_ADMIN: auth + '/admins/logouts',
};
