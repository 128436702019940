import { keys } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { DataSource, IDataSourSelect } from '../../types/status-source-type';
import Select, { Theme } from 'react-select';
import { CSSObject } from '@emotion/serialize';
import CreatableSelect from 'react-select/creatable';

type Props = {
  name: string;
  inputRef?: React.ClassAttributes<HTMLSelectElement>['ref'];
  classNameColumn?: string;
  options?: DataSource;
  onChange?: (value: string) => void;
  emptySelectionLabel?: string;
  disableAllValue?: boolean;
  setValue?: (name: string, value: any) => void;
  defaultValue?: string;
  translation?: boolean;
};

export function SearchSelect(props: Props) {
  const { formatMessage } = useIntl();
  const { options, name, emptySelectionLabel, inputRef, defaultValue, translation = true, onChange } = props;
  const [value, setValue] = useState<IDataSourSelect>();
  const [load, loaded] = useState(false);

  const convertOption = (options: DataSource): IDataSourSelect[] => {
    const data: IDataSourSelect[] = [];
    data.push({
      label: emptySelectionLabel ? formatMessage({ id: emptySelectionLabel }) : formatMessage({ id: 'All' }),
      value: '',
    });
    options &&
      keys(options).forEach((item: any) => {
        data.push({
          label: item && translation ? formatMessage({ id: options?.[item].label }) : options?.[item].label,
          value: item,
        });
      });
    return data;
  };

  useEffect(() => {
    if (defaultValue && options) {
      const filterData = convertOption(options).filter((option) => `${option.value}` === `${defaultValue}`);
      setValue(filterData[0]);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await loaded(true);
      if (defaultValue === undefined) setValue(undefined);
      await loaded(false);
    })();
  }, [defaultValue]);

  return (
    <div className={`column ${props.classNameColumn}`}>
      <div className="columns">
        <div className="column is-narrow">
          <input type="hidden" name={name} ref={inputRef as any} />
          <div style={{ minWidth: 200, maxWidth: 400 }}>
            {options && !load && (
              <Select
                value={value}
                onChange={(option) => {
                  props.setValue?.(name, option?.value);
                  option && setValue(option);
                  option && onChange?.(option?.value as string);
                }}
                options={convertOption(options)}
                placeholder={formatMessage({ id: emptySelectionLabel })}
                theme={themeSmall}
                styles={customStylesSmall as any}
                menuPosition={'fixed'}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export const themeSmall = (theme: Theme) => ({
  ...theme,
  spacing: {
    ...theme.spacing,
    controlHeight: 30,
    baseUnit: 2,
  },
});

export const customStylesSmall = {
  valueContainer: (provided: CSSObject) => ({
    ...provided,
    padding: '0 6px',
  }),
  option: (provided: CSSObject) => ({
    ...provided,
    padding: '0 6px',
    fontSize: '0.75rem',
  }),
  control: (styles: CSSObject) => ({ ...styles, fontSize: '0.75rem' }),
};

export function SelectInputChange(props: Props) {
  const { formatMessage } = useIntl();
  const { options, name, emptySelectionLabel, inputRef, defaultValue, translation = true, onChange } = props;
  const [value, setValue] = useState<IDataSourSelect>();
  const [load, loaded] = useState(false);

  const convertOption = (options: DataSource): IDataSourSelect[] => {
    const data: IDataSourSelect[] = [];
    data.push({
      label: emptySelectionLabel ? formatMessage({ id: emptySelectionLabel }) : formatMessage({ id: 'All' }),
      value: '',
    });
    options &&
      keys(options).forEach((item: any) =>
        data.push({
          label:
            item && translation
              ? formatMessage(
                  { id: options?.[item].label },
                  options?.[item]?.valueOfTranslation ? options?.[item]?.valueOfTranslation : {},
                )
              : options?.[item].label,
          value: item,
        }),
      );
    !!defaultValue &&
      data.filter((o) => `${o.value}` === `${defaultValue}`).length === 0 &&
      data.unshift({
        label: defaultValue as any,
        value: defaultValue,
      });
    return data;
  };

  useEffect(() => {
    if (defaultValue && options) {
      const filterData = convertOption(options).filter((option) => `${option.value}` === `${defaultValue}`);
      setValue(filterData[0]);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await loaded(true);
      if (defaultValue === undefined) {
        setValue({
          label: emptySelectionLabel ? formatMessage({ id: emptySelectionLabel }) : formatMessage({ id: 'All' }),
          value: '',
        });
      }
      await loaded(false);
    })();
  }, [defaultValue]);

  return (
    <div className={`column ${props.classNameColumn}`}>
      <div className="columns">
        <div className="column is-narrow">
          <input type="hidden" name={name} ref={inputRef as any} />
          <div style={{ minWidth: 200, maxWidth: 400 }}>
            {options && !load && (
              <CreatableSelect
                defaultValue={
                  convertOption(options).filter((option) => `${option.value}` === `${defaultValue}`) ?? {
                    label: defaultValue,
                    value: defaultValue,
                  }
                }
                value={value}
                onChange={(option) => {
                  props.setValue?.(name, option?.value);
                  option && setValue(option);
                  option && onChange?.(option?.value as string);
                }}
                options={convertOption(options)}
                placeholder={formatMessage({ id: emptySelectionLabel })}
                theme={themeSmall}
                styles={customStylesSmall as any}
                menuPosition={'fixed'}
                // isClearable
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
