export const wsConnect = (host: any) => ({ type: 'WS_CONNECT', host });
export const wsConnecting = (host: any) => ({ type: 'WS_CONNECTING', host });
export const wsConnected = (host: any) => ({ type: 'WS_CONNECTED', host });
export const wsDisconnect = (host: any) => ({ type: 'WS_DISCONNECT', host });
export const wsDisconnected = (host: any) => ({ type: 'WS_DISCONNECTED', host });
export const wsOnMessage = (json: any) => ({ type: 'WS_ON_MESSAGE', data: json });

const websocketInitialState = {
  host: '',
  message: '',
};

export function webSocket(state = { ...websocketInitialState }, action: any) {
  switch (action.type) {
    case 'WS_CONNECT':
      return {
        ...state,
        host: action.host,
      };

    case 'WS_ON_MESSAGE':
      return {
        ...state,
        data: action.data,
      };

    default:
      return state;
  }
}
