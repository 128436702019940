/* eslint-disable react/no-array-index-key */
import { History } from 'history';
import { get } from 'lodash';
import { parse, stringify } from 'query-string';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { AnyAction, Dispatch } from 'redux';
import { ValuesType } from 'utility-types';
import { TableCollapse } from '../../components/table-collapse';
import MsCell from '../blocks/ms-cell';
import MsCellHead from '../blocks/ms-cell-head';
import MsNoData from '../blocks/ms-nodata';
import MsOffsetPagination from '../blocks/ms-offset-pagination';
import MsPagination from '../blocks/ms-pagination';
import MsToolPanel from '../blocks/ms-tool-panel';
import { MsRouter } from '../models/ms-router';
import { MsType, ToolType } from '../models/ms-type';
import { Pagination } from '../models/pagination';
import { ValueOfTranslation } from '../types/status-source-type';
import { ButtonCreate } from './ms-button';
import { CheckBoxTable, CheckBoxTableAll, DataFormCheckBox } from './ms-checkbox';

type PaginationSearch =
  | Record<string, never>
  | {
      size: number;
      page: number;
      sort?: string;
    };

// type ItemType =

export interface MsIndexProps {
  /** Search condition match with application state */
  queryParam?: Record<string, unknown>;
  /** APIs communication flags for loading display */
  listRequested?: boolean;
  /** List model abstraction */
  items?: ({ id?: string } & Record<string, unknown>)[];
  /** Sync errors message between client and API response */
  error?: any;
  /** A dispatching function accepts an action or an async action; it then may
   * or may not dispatch one or more actions to the store */
  dispatch?: Dispatch<AnyAction>;
  /** History session to manage stack and persist state between sessions */
  history?: History;
  /** Hook for child components to get the router object and update when it changes */
  msRouter?: MsRouter;
  paginate?: Pagination;
  forceQueryParam?: Record<string, any>;
  extClass?: string;
  get?: (params: Record<string, unknown>) => void;
  conf?: MsType[];
  title?: string;
  iconTitle?: string;
  defaultSearch?: Record<string, any>;
  rootSearch?: Record<string, any>;
  toolConf?: ToolType[];
  disableResetSearchButton?: boolean;
  disableSearchButton?: boolean;
  creatable?: boolean;
  clickDetail?: boolean;
  notAutoFetchData?: boolean;
  paddingHeader?: number;
  expanded?: boolean;
  hideNumberRow?: boolean;
  useOffsetPagination?: boolean;
  getTotalPage?: () => Promise<number>;
  isChecked?: {
    checked: boolean;
    identify: string;
    notUncheck?: boolean;
  };
  getDataChecked?: (arr?: Record<string, unknown>[]) => void;
  titleRight?: string;
  isNotCard?: boolean;
  isNotHasDefaultSort?: boolean;
  help?: string;
  valueOfTranslation?: ValueOfTranslation;
  style?: React.CSSProperties;
  defaultCheckBox?: DataFormCheckBox[];
}

export interface MsIndexState {
  /** Initial search condition*/
  defaultSearch?: PaginationSearch & Record<string, unknown>;
  /** Flag to execute first search, if there is params in URL, it will be
   * executed regardless of the flag*/
  isInitRequest: boolean;
  /** Title displayed onto header of table */
  title: string;
  iconTitle?: string;
  rootSearch: Record<string, unknown>;
  defaultCheckBox: DataFormCheckBox[];
  titleRight?: string;
}

const defaultState = (props: MsIndexProps): MsIndexState => {
  const { isNotHasDefaultSort = false } = props;
  return {
    defaultSearch: isNotHasDefaultSort
      ? {
          size: 15,
          page: 1,
          ...props.defaultSearch,
        }
      : {
          size: 15,
          sort: 'createdAt,desc',
          page: 1,
          ...props.defaultSearch,
        },
    isInitRequest: true,
    title: props.title ?? '',
    iconTitle: props.iconTitle,
    titleRight: props.titleRight,
    rootSearch: { ...props.rootSearch },
    defaultCheckBox: props?.defaultCheckBox || [],
  };
};

// type a = ValuesType<MsIndexProps['items']>;
/**
 * Index abstract
 * @description Base class for standard list screen
 */
export abstract class MsIndexAbstract<
  Props extends MsIndexProps = MsIndexProps,
  State extends MsIndexState = MsIndexState,
> extends React.Component<Props, State> {
  /** Listen for changes to the current location */
  unlisten?: ReturnType<History['listen']>;

  state = defaultState(this.props) as State;

  CREATE_URL?: string;

  // constructor(props: Props) {
  //   super(props);
  //   this.renderHeadRow = this.renderHeadRow.bind(this);
  // }

  // abstract paginationData(): void;

  /** Method trigger API to get resource */
  fetchItems(params: Parameters<Exclude<MsIndexProps['get'], undefined>>[0]): void {
    this.props.get?.(params);
  }

  /** Method trigger row clicked */
  onRowClicked(row: ValuesType<Exclude<MsIndexProps['items'], undefined>>, index?: number): void {
    if (row.id) this.props.history!.push({ pathname: `${this.props.history!.location.pathname}/${row.id}` });
  }

  ////////////////////////////////////
  // LIFE CYCLE //////////////////////
  ////////////////////////////////////
  /**
   * Component did mount
   */
  componentDidMount() {
    // Merge page number into current search criteria
    const queryParam = Object.assign({}, this.state.defaultSearch, this.props.queryParam, {
      ...parse(this.props.history?.location.search ?? ''),
    });

    this.setupEvenHookDetectRouterChange();
    !this.props.notAutoFetchData && this.onSearchStart(queryParam, true);
  }

  /**
   * Component will un mount
   */
  async componentWillUnmount() {
    // To stop listening, call the function returned from history listen
    if (this.unlisten) await this.unlisten();
  }

  ////////////////////////////////////
  // SETUP ///////////////////////////
  ////////////////////////////////////
  /**
   * @description Create watcher event/hook to detect router change
   */
  setupEvenHookDetectRouterChange() {
    if (!this.props.history) return;
    // Listen for changes to the current location
    this.unlisten = this.props.history?.listen((location, action) => {
      if (!location.state) return;

      if (location.state === 'search') {
        let params: any = parse(location.search);
        const forceQueryParam = this.props.forceQueryParam;

        if (forceQueryParam) {
          params = {
            ...params,
            ...forceQueryParam,
          };
        }

        this.fetchItems(params);
      } else if ((location.state as any).search === true) {
        const params = {
          ...(this.props.queryParam ?? {}),
          ...parse(location.search),
          ...((location.state as any).page ? { page: 1 } : {}),
          ...(this.props.forceQueryParam ?? {}),
        };

        this.fetchItems(params);
      }
    });
  }

  ////////////////////////////////////
  // SEARCH EVENS HANDLE /////////////
  ////////////////////////////////////
  /**
   * @description Search start handle
   * @param search
   */
  onSearchStart(search?: Record<string, unknown>, replace?: boolean) {
    if (!this.props.history) return;

    const location = { search: stringify({ ...(search ?? {}), ...this.state.rootSearch }), state: 'search' };
    if (replace) this.props.history!.replace(location);
    else this.props.history!.push(location);
    // Push a new entry onto the history stack with search condition
  }

  /**
   * @description On event pagination change page number handle
   * @param page
   */
  onPageChanged = (obj?: Record<string, unknown>) => {
    // Merge page number into current search criteria
    const newQueryParams = Object.assign({}, this.props?.queryParam, obj);
    this.onSearchStart(newQueryParams);
  };

  onSortChanged = (sort: string) => {
    const newQueryParams = Object.assign({}, this.props.queryParam, { sort });
    this.onSearchStart(newQueryParams);
  };

  /**
   * @desc On event change controls in level search
   * @param obj
   */
  onLevelSearchChanged(obj?: Record<string, unknown>, isForceUpdate?: boolean) {
    // * Reset page when search change
    const searchParams = Object.assign({}, obj, { page: 1 });

    // Merge page number into current search criteria
    const newQueryParams = !isForceUpdate ? Object.assign({}, this.props.queryParam, searchParams) : searchParams;
    this.onSearchStart(newQueryParams);
  }

  refresh() {
    this.props.isChecked?.notUncheck !== true && this.refreshCheckBox();
    this.props.history && this.fetchItems(parse(this.props.history.location.search));
  }

  /**
   * @desc On Change Checkbox
   * @param obj
   */
  getDataChecked(data: DataFormCheckBox[]): void {
    const tmp: ValuesType<any>[] = [];
    data.forEach((item) => {
      if (item.checked === true) {
        tmp.push(item.data);
      }
    });
    this?.props?.getDataChecked?.(tmp ?? []);
  }

  setDataCheckBox(data?: DataFormCheckBox) {
    if (!data) return;
    const tmp = [...(!!this.state.defaultCheckBox ? this.state.defaultCheckBox : [])];
    if (this.state.defaultCheckBox.find((i: any) => i.id.toString() === data.id.toString())) {
      this.setState({
        defaultCheckBox: tmp?.map((p) =>
          p?.id.toString() === data.id.toString() ? { ...p, checked: data.checked } : p,
        ),
      });
      this.getDataChecked(
        tmp?.map((p) => (p?.id.toString() === data.id.toString() ? { ...p, checked: data.checked } : p)),
      );
    } else {
      this.setState({
        defaultCheckBox: [...[data], ...tmp],
      });
      this.getDataChecked([...[data], ...tmp]);
    }
  }

  setAllDataCheckBox(data?: DataFormCheckBox[]) {
    if (!data) return;
    this.setState({
      defaultCheckBox: data,
    });
    this.getDataChecked(data);
  }

  setDefaultCheckBox() {
    if (!this.props.defaultCheckBox) return;
    this.setState({
      defaultCheckBox: (this.props.defaultCheckBox as DataFormCheckBox[]) || [],
    });
    this.getDataChecked((this.props.defaultCheckBox as DataFormCheckBox[]) || []);
  }

  refreshCheckBox() {
    this.getDataChecked([]);
    this.setState({
      defaultCheckBox: [],
    });
  }

  ////////////////////////////////////
  // RENDER //////////////////////////
  ////////////////////////////////////
  /** Render table head, as the model conf itself */
  renderHeadRow(): React.ReactNode {
    const sortParams = (get(this.props.queryParam, 'sort', '') as string).split(',');
    return (
      <tr>
        {this.props.expanded && (
          <th>
            <label />
          </th>
        )}
        {!this.props.hideNumberRow ? (
          <th>
            <label>#</label>
          </th>
        ) : null}
        {this.props.isChecked && (
          <th>
            <CheckBoxTableAll
              getDataCheckBox={this.state.defaultCheckBox}
              setDefaultCheckBox={() => this.setDefaultCheckBox()}
              setDataCheckBox={(obj) => this.setDataCheckBox(obj)}
              setAllDataCheckBox={(arr) => this.setAllDataCheckBox(arr)}
              identify={this.props.isChecked.identify}
              items={this.props.items}
              queryParam={this.props.queryParam}
              isChecked={this.props.isChecked}
              listRequested={this.props.listRequested}
            />
          </th>
        )}
        {this.props.clickDetail && (
          <th>
            <label />
          </th>
        )}
        {this.props.conf?.map((pName) => {
          if (pName.hidden) return null;
          return (
            <MsCellHead
              key={pName.field}
              field={pName.field}
              name={pName.name}
              customize={pName.customizeHeader}
              dataType={pName.type}
              sortable={!!pName.sort}
              sortOrder={sortParams[0] === pName.sort ? (sortParams[1] as any) : undefined}
              onSortChange={this.onSortChanged}
              abbrTitle={pName.abbrTitle}
              sortName={pName.sortName}
              valueOfTranslation={pName.valueOfTranslation}
            />
          );
        })}
      </tr>
    );
  }

  renderButtonDetail(item: ValuesType<Exclude<MsIndexProps['items'], undefined>>): React.ReactNode {
    return (
      <FormattedMessage id="Detail">
        {(trans: any) => (
          <button
            className="button is-primary has-tooltip-arrow"
            data-tooltip={trans}
            onClick={() => this.onRowClicked(item)}>
            <span className="icon">
              <i className="mdi mdi-18px mdi-dots-horizontal" />
            </span>
          </button>
        )}
      </FormattedMessage>
    );
  }

  renderTableRow(index: number, rowData: ValuesType<Exclude<Props['items'], undefined>>): ReactNode {
    return (
      <tr draggable={false} key={index}>
        {!this.props.hideNumberRow ? (
          <td className="has-text-weight-bold is-unset-max-width is-narrow">
            {index +
              1 +
              (this.props.paginate?.size ?? this.props.items?.length ?? 1) * ((this.props.paginate?.number ?? 1) - 1)}
          </td>
        ) : null}

        {this.props.isChecked && (
          <CheckBoxTable
            getDataCheckBox={this.state.defaultCheckBox}
            setDefaultCheckBox={() => this.setDefaultCheckBox()}
            setDataCheckBox={(obj) => this.setDataCheckBox(obj)}
            setAllDataCheckBox={(arr) => this.setAllDataCheckBox(arr)}
            identify={this.props.isChecked.identify}
            rowData={rowData}
            listRequested={this.props.listRequested}
          />
        )}

        {this.props.clickDetail && (
          <td key="detail" className="has-text-centered is-unset-max-width is-overflow-visible is-narrow">
            {this.renderButtonDetail(rowData)}
          </td>
        )}

        {this.renderTableRowContent(rowData)}
      </tr>
    );
  }

  /** Render table row, as the model conf itself */
  // abstract renderTableRow(rowData: ValuesType<Exclude<Props['items'], undefined>>): ReactNode;
  renderTableRowContent(rowData: ValuesType<Exclude<Props['items'], undefined>>): ReactNode {
    return (
      <>
        {this.props.conf?.map((pName) => {
          if (pName.hidden) return null;

          const data = pName.customizeIndexData
            ? pName.customizeIndexData(pName, rowData)
            : get(rowData, pName.field, '');
          const extData = get(rowData, pName.fieldExt ?? '');

          if (pName.customizeIndexDisplay) {
            return pName.customizeIndexDisplay(data, extData, this);
          }
          return (
            <MsCell
              key={pName.field}
              cellType={pName.displayType ?? pName.type}
              data={data as any}
              extData={extData}
              dataSource={pName.dataSource}
              translation={pName.translation}
              currencyDecimal={pName.currencyDecimal}
              isCopy={pName.isCopy}
            />
          );
        })}
      </>
    );
  }

  /** Render search controls in level left of search panel */
  renderSearchLevelLeft(): ReactNode {
    return null;
  }

  renderToolbar() {
    const searchLevelLeft = this.renderSearchLevelLeft();

    return (
      <>
        {((this.props.toolConf?.length ?? 0) > 0 || searchLevelLeft != null) && (
          <div className="is-card-toolbar px-2 py-4">
            <MsToolPanel
              toolConf={this.props.toolConf ?? []}
              queryParam={this.props.queryParam ?? {}}
              onSearchChanged={(obj, isForceUpdate) => this.onLevelSearchChanged(obj, isForceUpdate)}
              refreshSearchCriteria={() => this.onLevelSearchChanged(this.state.defaultSearch, true)}
              disableResetButton={this.props.disableResetSearchButton}
              disableSearchButton={this.props.disableSearchButton}>
              {searchLevelLeft}
            </MsToolPanel>
            {this.props.help && <p className="help has-text-info mt-5">{this.props.help}</p>}

            {/* {this.state.tokenSearch.length > 0 ? (
                <MsSearch
                  tokenSearch={this.state.tokenSearch}
                  queryParam={this.props.queryParam ?? {}}
                  onSearchChanged={this.onLevelSearchChanged}
                  refreshSearchCriteria={() => this.onLevelSearchChanged(this.state.defaultSearch, true)}>
                  {searchLevelLeft}
                </MsSearch>
              ) : (
                <div className="level MS_Search-container">
                  <div className="level-left">{searchLevelLeft}</div>
                </div>
              )} */}
          </div>
        )}
        {this.props.paddingHeader && <div style={{ padding: this.props.paddingHeader }} />}
      </>
    );
  }

  renderRightHeader(): React.ReactNode {
    return (
      <>
        {this.props.creatable && (
          // <button
          //   type="button"
          //   className={`button is-small is-primary`}
          //   onClick={() =>
          //     this.props.history?.push(this.CREATE_URL || `${this.props.history.location.pathname}/create`)
          //   }>
          //   <span className="icon">
          //     <i className="mdi mdi-plus default" />
          //   </span>
          //   <span>
          //     <FormattedMessage id="Create" />
          //   </span>
          // </button>
          <ButtonCreate
            onClick={() =>
              this.props.history?.push(this.CREATE_URL || `${this.props.history.location.pathname}/create`)
            }
          />
        )}
      </>
    );
  }

  renderTFoot(): ReactNode {
    return null;
  }

  renderExpandedRowRender(rowData: ValuesType<Exclude<Props['items'], undefined>>): ReactNode {
    return (
      <>
        {this.props.conf?.map((pName) => {
          if (pName.hidden) return null;

          const data = pName.customizeIndexData
            ? pName.customizeIndexData(pName, rowData)
            : get(rowData, pName.field, '');
          const extData = get(rowData, pName.fieldExt ?? '');

          if (pName.customizeExpandedRowRender) {
            return pName.customizeExpandedRowRender(data, extData, this);
          }
          return null;
        })}
      </>
    );
  }
  renderContent() {
    return (
      <div className="b-table" style={this.props?.style}>
        {/* Render field to support table sort on mobile */}

        {/* Render table list container */}
        <div className="table-wrapper table-container">
          <table
            className={`${
              this.props.listRequested && 'is-loading'
            } table has-mobile-cards is-hoverable is-fullwidth is-bordered`}>
            <thead>{this.renderHeadRow()}</thead>

            <tbody>
              {this.props.items?.map((item, index) => (
                <>
                  {this.props.expanded ? (
                    <TableCollapse
                      queryParam={this?.props?.queryParam as any}
                      colSpan={
                        this.props.clickDetail
                          ? (this.props.conf?.length as number) + 1
                          : (this.props.conf?.length as number)
                      }
                      key={index}
                      title={
                        <>
                          <td className="has-text-weight-bold is-unset-max-width is-narrow">
                            {index +
                              1 +
                              (this.props.paginate?.size ?? this.props.items?.length ?? 1) *
                                ((this.props.paginate?.number ?? 1) - 1)}
                          </td>

                          {this.props.isChecked && (
                            <CheckBoxTable
                              getDataCheckBox={this.state.defaultCheckBox}
                              setDefaultCheckBox={() => this.setDefaultCheckBox()}
                              setDataCheckBox={(obj) => this.setDataCheckBox(obj)}
                              setAllDataCheckBox={(arr) => this.setAllDataCheckBox(arr)}
                              identify={this.props.isChecked.identify}
                              rowData={item}
                              listRequested={this.props.listRequested}
                            />
                          )}

                          {this.props.clickDetail && (
                            <td
                              key="detail"
                              className="has-text-centered is-unset-max-width is-overflow-visible is-narrow">
                              {this.renderButtonDetail(item as any)}
                            </td>
                          )}

                          {this.renderTableRowContent(item as any)}
                        </>
                      }>
                      {this.renderExpandedRowRender(item as any)}
                    </TableCollapse>
                  ) : (
                    this.renderTableRow(index, item as any)
                  )}
                </>
              ))}
            </tbody>
            {this.props.items?.length ? this.renderTFoot() : null}
          </table>

          {!this.props.items?.length && <>{this.renderNoData()}</>}
        </div>
        {/* Render pagination container */}
        {this.props.useOffsetPagination ? (
          <MsOffsetPagination
            onSearchChanged={this.onPageChanged}
            page={this.props.paginate?.number}
            size={this.props.paginate?.size}
            numberOfElements={this.props.items?.length ?? 0}
            getTotal={this.props.getTotalPage}
          />
        ) : (
          <MsPagination onSearchChanged={this.onPageChanged} pagination={this.props.paginate} />
        )}
      </div>
    );
  }

  /**
   * @description Render index layout
   */
  render() {
    return (
      <>
        <section className={`section ${this.props.extClass ?? ''}`}>
          <div
            className={`${
              this.state.title ? `${this.props.isNotCard ? '' : 'card'}` : ''
            } has-table has-mobile-sort-spaced has-date-range-picker`}>
            {(this.state.title || this.state.iconTitle) && (
              <header className="card-header">
                <p className="card-header-title">
                  {this.state.iconTitle && (
                    <span className="icon">
                      <i className={`mdi mdi-${this.state.iconTitle}`} />
                    </span>
                  )}
                  <span>
                    {this.props.valueOfTranslation ? (
                      <FormattedMessage id={this?.props?.title ?? ''} values={this.props?.valueOfTranslation} />
                    ) : (
                      <FormattedMessage id={this.props?.title ?? ''} />
                    )}{' '}
                    {this.state.titleRight && `- ${this.state.titleRight}`}
                  </span>
                </p>
                {this.renderRightHeader()}
              </header>
            )}

            {/* Render search container */}
            {this.renderToolbar()}

            <div className={`${this.props.isNotCard ? '' : 'card-content'}`}>
              <div>{this.renderContent()}</div>
            </div>
          </div>
        </section>

        {this.renderMore()}
      </>
    );
  }

  renderMore(): ReactNode {
    return;
  }

  /**
   * @description If data source is empty, display no data found view
   */
  renderNoData(): React.ReactNode {
    return <MsNoData />;
  }
}
