import React, { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useConfirm } from '../../../../../ms-core/hook/use-confirm';
import { showToast } from '../../../../../ms-core/utils/toast';
import { getUIConfigById, updateUIConfigById } from '../../../../../services/common/website-builders';
import Skeleton from 'react-loading-skeleton';
import { EditorControl } from '../../../../../components/editor';
import SwitchButton from '../../../../../components/switch-button';
import { DataSource } from '../../../../../ms-core/types/status-source-type';
import CustomSelect from '../../../../../components/custom-selection';
import { Input } from '../../../../../ms-core/blocks/controls';
import IframeControl from '../../../../../components/iframe-control';
import { HTTMLEditorControl } from '../../../../../components/html-editor-control';
import CustomSelectWithOnChange from '../../../../../components/custom-selection/custom-selection-with-onchange';
import { formatHtml } from '../../../../../utils/helper';
import { ModalUploadFileServicerWithSuggestedPhotosForm } from '../../../../../components/modal-upload-image/select-method-upload-photos';
import 'react-loading-skeleton/dist/skeleton.css';

type Props = {
  lang: string;
  title?: string;
  type?: string;
};

export enum WelcomePopupType {
  HTML = 'HTML',
  IMAGE = 'IMAGE',
}

export const WelcomePopupTypeSource: DataSource = {
  [WelcomePopupType.HTML]: { label: 'Html', color: 'info', value: WelcomePopupType.HTML },
  [WelcomePopupType.IMAGE]: { label: 'Image', color: 'warning', value: WelcomePopupType.IMAGE },
};

export const FormControlContentWelcomePopup = memo((props: Props) => {
  const { lang, type = '', title = '' } = props;
  const { control, handleSubmit, reset, watch, errors, register, setValue } = useForm({ shouldUnregister: false });
  const { showConfirm } = useConfirm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDocEditor, setIsDocEditor] = useState<string>('WORD_EDITOR');

  const fetchData = async (lang: string) => {
    setIsLoading(true);
    setTimeout(() => {
      getUIConfigById(lang, type)
        .then((res) => {
          reset({
            content: {
              enable: res?.enable ?? false,
              content: formatHtml(res?.content) ?? '',
              image: res?.image ?? '',
              type: res?.type ?? WelcomePopupType.HTML,
            },
          });
        })
        .catch(() =>
          reset({
            content: {
              enable: false,
              content: '',
              image: '',
              type: WelcomePopupType.HTML,
            },
          }),
        )
        .finally(() => setIsLoading(false));
    }, 100);
  };

  useEffect(() => {
    if (!lang) return;

    fetchData(lang);
  }, [lang]);

  const onSubmit = (values: Record<string, any>) => {
    updateUIConfigById(values.content, lang, type).then(() => {
      showToast.success('Update Resource Success');
      fetchData(lang);
    });
  };

  const onConfirmSubmit = (values: Record<string, any>) => {
    showConfirm('You have changes made. Would you like to save current progress?', 'Accept', {
      onConfirm: () => onSubmit(values),
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onConfirmSubmit)}>
        <div className={`field is-horizontal p-5 is-border`}>
          <div className="field-label is-normal has-text-left pb-4	">
            <div className="label">{isLoading ? <Skeleton count={1} /> : <FormattedMessage id={title} />}</div>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <div className="info-description">
                  <div className="field pt-2">
                    <label className="label">
                      <div className="level">
                        <div className="level-left">
                          <div className="level-item">
                            {isLoading ? <Skeleton count={1} /> : <FormattedMessage id="Enable" />}
                          </div>
                        </div>
                        <div className="level-right">
                          <div className="level-item">
                            {isLoading ? (
                              <Skeleton count={1} />
                            ) : (
                              <button onClick={() => fetchData(lang)} type="button" className="button is-small ml-2">
                                <span className="icon">
                                  <i className="mdi mdi-refresh default" />
                                </span>
                                <span>
                                  <FormattedMessage id="Refresh data" />
                                </span>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </label>
                    <div className="control">
                      {isLoading ? (
                        <Skeleton width="100%" count={1} />
                      ) : (
                        <>
                          <SwitchButton
                            register={register}
                            name="content.enable"
                            value={watch('content.enable')}
                            mbNumber={0}
                            setValue={setValue}
                            turnOff
                          />
                        </>
                      )}
                    </div>
                  </div>

                  <div className="field pt-2">
                    <label className="label">
                      <div className="level">
                        <div className="level-left">
                          <div className="level-item">
                            {isLoading ? <Skeleton count={1} /> : <FormattedMessage id="Type" />}
                          </div>
                        </div>
                      </div>
                    </label>
                    <div className="control">
                      {isLoading ? (
                        <Skeleton width="100%" count={1} />
                      ) : (
                        <>
                          <CustomSelect
                            name="content.type"
                            options={WelcomePopupTypeSource}
                            errors={errors?.type && true}
                            placeholder="Type"
                            defaultValue={watch('content')?.type}
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: 'This field is required!',
                              },
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>

                  {watch('content.type') === WelcomePopupType.IMAGE && (
                    <div className="field pt-2">
                      <label className="label">
                        <div className="level">
                          <div className="level-left">
                            <div className="level-item">
                              {isLoading ? <Skeleton count={1} /> : <FormattedMessage id="Image" />}
                            </div>
                          </div>
                        </div>
                      </label>
                      <div className="control">
                        {isLoading ? (
                          <Skeleton width="100%" count={1} />
                        ) : (
                          <>
                            <ModalUploadFileServicerWithSuggestedPhotosForm
                              onChange={(e) => {
                                setValue('content.image', e);
                              }}
                              hiddenCopy
                              isHasCard={false}
                              defaultValue={watch(`content.image`)}
                            />
                            <Input
                              className={`mt-2 input${errors?.content?.image ? ' is-danger' : ''}`}
                              name="content.image"
                              control={control}
                              rules={{
                                required: {
                                  value: false,
                                  message: 'This field is required!',
                                },
                              }}
                              placeholder="Image"
                              defaultValue={watch('content.image')}
                            />
                            {errors?.content?.image && (
                              <p className="help has-text-danger">
                                {errors?.content.image.message && (
                                  <FormattedMessage id={errors?.content.image.message} />
                                )}
                              </p>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  )}

                  {watch('content.type') === WelcomePopupType.HTML && (
                    <div className="field pt-2">
                      <label className="label">
                        <div className="level">
                          <div className="level-left">
                            <div className="level-item">
                              {isLoading ? <Skeleton count={1} /> : <FormattedMessage id="Type editor" />}
                            </div>
                          </div>
                        </div>
                      </label>
                      <div className="control">
                        {isLoading ? (
                          <Skeleton width="100%" count={1} />
                        ) : (
                          <CustomSelectWithOnChange
                            disableAllValue
                            defaultValue="WORD_EDITOR"
                            label="Type"
                            name="type"
                            options={{
                              WORD_EDITOR: { label: 'Word Editor', value: 'WORD_EDITOR', color: 'primary' },
                              HTML_EDITOR: { label: 'Html Editor', value: 'HTML_EDITOR', color: 'danger' },
                            }}
                            placeholder="Type editor"
                            className="is-min-width-100"
                            onChangeData={(e) => setIsDocEditor(e)}
                            isNormalSize
                          />
                        )}
                      </div>
                    </div>
                  )}

                  {watch('content.type') === WelcomePopupType.HTML && (
                    <div className="field pt-2">
                      <label className="label">
                        <div className="level">
                          <div className="level-left">
                            <div className="level-item">
                              {isLoading ? <Skeleton count={1} /> : <FormattedMessage id="Content" />}
                            </div>
                          </div>
                        </div>
                      </label>
                      <div className="control">
                        {isLoading ? (
                          <Skeleton width="100%" height="50vh" count={1} />
                        ) : (
                          <>
                            {isDocEditor === 'WORD_EDITOR' && (
                              <EditorControl
                                name="content.content"
                                control={control}
                                defaultValue={watch('content')?.content}
                              />
                            )}
                            {isDocEditor === 'HTML_EDITOR' && (
                              <>
                                <IframeControl content={watch('content.content') ?? ''} width="100%" height="65vh" />
                                <HTTMLEditorControl
                                  defaultValue={watch('content.content')}
                                  rules={{
                                    required: {
                                      value: true,
                                      message: 'This field is required!',
                                    },
                                  }}
                                  name="content.content"
                                  control={control}
                                  width="100%"
                                  height="65vh"
                                />
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="field">
          <div className="field-label is-normal" />
          <div className="field-body">
            <div className="field">
              {isLoading ? (
                <Skeleton count={1} />
              ) : (
                <button type="submit" className="button is-primary">
                  <span>
                    <FormattedMessage id="Submit" />
                  </span>
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
});
