import React from 'react';
import { PLATFORM } from '../../utils/secrets';
import { IAboutProps } from './about.type';

export const AboutPage: React.FC<IAboutProps> = () => {
  return (
    <div className={`platform-configuration section`}>
      <div className="columns is-desktop">
        <div className="column">
          <div className="card has-height-medium">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title p-3">
                  <span className="icon">
                    <i className="mdi mdi-help-circle" />
                  </span>
                  <span>About {PLATFORM}</span>
                </p>
              </header>
              <div className="card-content p-0">
                <div className="column ">
                  <div className="column is-full">Backstage {PLATFORM}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
