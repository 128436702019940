import React from 'react';
import { keys } from 'lodash';
import { useIntl } from 'react-intl';
import { DataSource } from '../../../../../ms-core/types/status-source-type';

type SelectProps = {
  label?: string;
  name: string;
  options: DataSource;
  defaultValue?: string;
  placeholder?: string;
  className?: string;
  errors?: any;
  onChangeData?: (data: string) => void;
  disableAllValue?: boolean;
};

export function Select(props: SelectProps) {
  const { options, name, placeholder, defaultValue, onChangeData } = props;

  const { formatMessage } = useIntl();

  return (
    <div className={`select`}>
      <select name={name} value={defaultValue} onChange={(e) => onChangeData && onChangeData(e.target.value)}>
        {!props.disableAllValue && (
          <option key="" value="">
            {placeholder ? formatMessage({ id: placeholder }) : ''}
          </option>
        )}
        {options &&
          keys(options).map((item: any) => (
            <option key={item} value={item}>
              {options?.[item].label && formatMessage({ id: options?.[item].label })}
            </option>
          ))}
      </select>
    </div>
  );
}
