import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  onClick: () => void;
};

export const ButtonUpdate = ({ onClick }: Props) => {
  return (
    <button type="button" className="button is-small is-primary" onClick={onClick}>
      <span className="icon">
        <i className="mdi mdi-pencil-box-multiple default" />
      </span>
      <span>
        <FormattedMessage id="Update" />
      </span>
    </button>
  );
};

export const ButtonCreate = ({ onClick }: Props) => {
  return (
    <button type="button" className={`button is-small is-primary`} onClick={onClick}>
      <span className="icon">
        <i className="mdi mdi-plus default" />
      </span>
      <span>
        <FormattedMessage id="Create" />
      </span>
    </button>
  );
};
