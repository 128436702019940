/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import moment, { Moment } from 'moment';
import { ToolType } from '../../models/ms-type';
import GGDateRangePicker from '../date-picker/gg-date-range-picker';
import { FormattedMessage, useIntl } from 'react-intl';

export type Preset = { label: string; range: [Moment, Moment] }[];

function defaultPreset(): Preset {
  return [
    { label: 'Today', range: [moment().startOf('d'), moment().endOf('d')] },
    { label: 'Yesterday', range: [moment().subtract(1, 'd').startOf('d'), moment().subtract(1, 'd').endOf('d')] },
    { label: 'Last 7 days', range: [moment().subtract(7, 'd').startOf('d'), moment().subtract(1, 'd').endOf('d')] },
    { label: 'This week', range: [moment().startOf('w'), moment().endOf('w')] },
    {
      label: 'Last week',
      range: [moment().subtract(1, 'w').startOf('isoWeek'), moment().subtract(1, 'w').endOf('isoWeek')],
    },
    { label: 'Last 30 days', range: [moment().subtract(30, 'd').startOf('d'), moment().subtract(1, 'd').endOf('d')] },
    { label: 'This month', range: [moment().startOf('M'), moment().endOf('M')] },
    { label: 'Last month', range: [moment().subtract(1, 'M').startOf('M'), moment().subtract(1, 'M').endOf('M')] },
    /**
     * ! BE not support
     */
    // { label: 'This quarter', range: [moment().startOf('Q'), moment().endOf('Q')] },
    // { label: 'Last quarter', range: [moment().subtract(1, 'Q').startOf('Q'), moment().subtract(1, 'Q').endOf('Q')] },
  ];
}

function defaultPresetOldData(): Preset {
  return [
    { label: '3 month ago', range: [moment().subtract(2, 'M').startOf('M'), moment().subtract(2, 'M').endOf('M')] },
    { label: '4 month ago', range: [moment().subtract(3, 'M').startOf('M'), moment().subtract(3, 'M').endOf('M')] },
    { label: '5 month ago', range: [moment().subtract(4, 'M').startOf('M'), moment().subtract(4, 'M').endOf('M')] },
  ];
}

type Props = {
  classNameColumn?: string;
  onRemoveTag: (names: string[]) => void;
  conf: ToolType;
  value: [string?, string?];
  inputRef?: React.ClassAttributes<HTMLInputElement>['ref'];
  setValue: (name: string, data: unknown) => void;
  submitChange: () => void;
  placeholder?: string;
  preset?: Preset | (() => Preset);
  useDefaultPreset?: boolean;
  type?: 'isNotToday' | 'isOldData';
};

export default function DateRangePicker(props: Props) {
  const { formatMessage } = useIntl();
  if (props.conf.names.length < 2) throw new Error('TokenFiltered of "dateRange" must have length is 2');

  function onOk(dates: [Moment, Moment]) {
    props.setValue?.(props.conf.names[0] ?? 'start', dates[0].toISOString());
    props.setValue?.(props.conf.names[1] ?? 'end', dates[1].toISOString());
    props.submitChange();
  }

  function onClickPresetButton(range: [Moment, Moment]) {
    return (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      onOk(range);
    };
  }

  function compareRange(range1: [Moment, Moment], range2: [string?, string?]): boolean {
    if (!range2[0] || !range2[1]) return false;
    if (range1[0].diff(moment(range2[0]), 's')) return false;
    if (range1[1].diff(moment(range2[1]), 's')) return false;
    return true;
  }

  const renderTypePresets = () => {
    switch (props.type) {
      case 'isNotToday':
        return defaultPreset().filter((item) => item.label !== 'Today');
      case 'isOldData':
        return defaultPresetOldData();
      default:
        return defaultPreset();
    }
  };

  const preset = props.useDefaultPreset
    ? renderTypePresets()
    : typeof props.preset === 'function'
    ? props.preset()
    : props.preset;

  return (
    <div className={`column${props.classNameColumn != null ? props.classNameColumn : ' is-full'}`}>
      <div className="columns is-multiline is-variable is-1">
        <div className="column is-narrow">
          <input
            key={'_' + props.conf.names[0]}
            name={props.conf.names[0]}
            ref={props.inputRef}
            className="is-hidden"
          />
          <input
            key={'_' + props.conf.names[1]}
            name={props.conf.names[1]}
            ref={props.inputRef}
            className="is-hidden"
          />
          <GGDateRangePicker
            value={[
              props.value[0] ? moment(props.value[0]) : undefined,
              props.value[1] ? moment(props.value[1]) : undefined,
            ]}
            onOk={onOk}
            placeholder={props.placeholder && formatMessage({ id: props.placeholder })}
          />
        </div>
        {preset?.map((item, index) => (
          <div key={index} className="column is-narrow">
            <button
              className={'button is-small ' + (compareRange(item.range, props.value) ? 'is-success' : '')}
              onClick={onClickPresetButton(item.range)}>
              {item.label && <FormattedMessage id={item.label} />}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}
