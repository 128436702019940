import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Select } from '../../components/select';
import { ModalUploadFile } from '../../../../../components/modal-upload-image';
import Tabs from '../../../../../components/tab/Tab';
import TabPane from '../../../../../components/tab/TabPanel/tab-panel';
import { LANGUAGE } from '../../../../../hook/use-lang';
import { DataSource } from '../../../../../ms-core/types/status-source-type';
import { showToast } from '../../../../../ms-core/utils/toast';
import { getLangUIConfig, getUIConfigById, updateUIConfigById, getAdminUIConfigById } from '../../../../../services';
import { UI_CONFIG } from '../../../../../utils/configs-admin/ui-config';
import { SectionDynamic } from './section-dynamic.builder';
import { ISectionDynamicProp } from './section-dynamic.type';
import { ArrayJson, ObjectJson } from './sections';

export const SectionDynamicPage: React.FC<ISectionDynamicProp> = () => {
  const params = useParams();

  const sectionIdUrl = (params as Record<string, any>)?.id;

  const [response, setResponse] = useState<Record<string, any>>();

  const [configs, setConfigs] = useState<Record<string, any>>();

  const [lang, setLang] = useState<string>(LANGUAGE.EN);

  const [languageTypeSource, setLanguageTypeSource] = useState<DataSource>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const configUI = useMemo(() => {
    return UI_CONFIG.filter((i) => i.id === sectionIdUrl)[0];
  }, [sectionIdUrl]);

  const changeDataFollowLang = useCallback(async (lang: string) => {
    setLang(lang as LANGUAGE);
  }, []);

  const fetchDataLang = useCallback(() => {
    setIsLoading(true);

    getLangUIConfig()
      .then((resLang) => {
        const languageTypeSourceData: DataSource = {};
        resLang?.forEach((item: Record<string, any>) => {
          languageTypeSourceData[item?.language] = {
            label: item.name,
            value: item?.language,
            color: 'white',
          };
        });
        setLanguageTypeSource(languageTypeSourceData);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const fetchData = useCallback(
    (lang: string) => {
      if (!(configUI?.resource ?? sectionIdUrl) || !configs) return;

      setIsLoading(true);
      setTimeout(() => {
        getUIConfigById(lang, configUI?.resource ?? sectionIdUrl)
          .then((res) => {
            setResponse(res);
          })
          .finally(() => setIsLoading(false));
      }, 100);
    },
    [configUI?.resource, configs, sectionIdUrl],
  );

  const onUpdateData = useCallback(
    (values: Record<string, any>, callBack?: () => void) => {
      setIsLoading?.(true);
      updateUIConfigById(values, lang, configUI?.resource ?? sectionIdUrl)
        .then(() => {
          showToast.success('Update resource success');
          fetchData(lang);
          callBack?.();
        })
        .finally(() => setIsLoading(false));
    },
    [configUI?.resource, fetchData, lang, sectionIdUrl],
  );

  const loadConfigs = useCallback(async () => {
    setIsLoading(true);
    getAdminUIConfigById('en', configUI?.resourceConfig ?? sectionIdUrl)
      .then((res) => {
        setConfigs(res);
      })
      .finally(() => setIsLoading(false));
  }, [configUI?.resourceConfig, sectionIdUrl]);

  useEffect(() => {
    fetchDataLang();
  }, [fetchDataLang]);

  useEffect(() => {
    if (!lang || !configs) return;

    fetchData(lang);
  }, [lang, configs, fetchData]);

  useEffect(() => {
    if (!(configUI?.resourceConfig ?? sectionIdUrl)) return;

    loadConfigs();
  }, [sectionIdUrl, configUI, loadConfigs]);

  return (
    <>
      <div className={`platform-configuration section`}>
        <div className="card has-height-medium">
          <div className="card">
            <header className="card-header">
              <a className="card-header-icon">
                <button className="is-small button is-ghost mr-0">
                  {!!languageTypeSource && (
                    <Select
                      onChangeData={changeDataFollowLang}
                      defaultValue={lang}
                      name="lang"
                      options={languageTypeSource}
                      disableAllValue
                    />
                  )}
                </button>
                <ModalUploadFile folderName="ui-config" isHasAccessPublic />
              </a>
            </header>
            <div className="card-content pt-0">
              <div className="content">
                <div className="platform-configuration">
                  {!!response && (
                    <Tabs hiddenTabName={configs?.hiddenTabName ?? false}>
                      {configs?.configs?.map((cfg: Record<string, any>, key: number) => {
                        if (cfg.type === 'array') {
                          return (
                            <TabPane name={cfg.title} key={key + 1}>
                              <ArrayJson
                                sectionId={configUI?.resource ?? sectionIdUrl}
                                lang={lang}
                                configs={cfg}
                                parentConfigs={configs}
                                items={_.get(response, cfg.nameKey || '', []) as Record<string, any>[]}
                                parentItem={response as Record<string, any>}
                                onRefresh={() => fetchData(lang)}
                                response={response}
                                onUpdateData={(_, c) =>
                                  onUpdateData({ ...response, ...SectionDynamic.getDataSubmit() }, c)
                                }
                                isLoading={isLoading}
                                // dataOfObjectHasArray={response}
                              />
                            </TabPane>
                          );
                        }
                        return (
                          <TabPane name={cfg.title} key={key + 1}>
                            <ObjectJson
                              onUpdateData={(_, c) =>
                                onUpdateData({ ...response, ...SectionDynamic.getDataSubmit() }, c)
                              }
                              isLoading={isLoading}
                              conf={cfg.listConf}
                              response={response}
                              nameKey={cfg.nameKey}
                              item={
                                (cfg.nameKey ? _.get(response, cfg.nameKey || '', {}) : response) as Record<string, any>
                              }
                              parentItem={response as Record<string, any>}
                              title={cfg?.title}
                              parentConfigs={configs}
                              configs={cfg}
                              onRefresh={() => fetchData(lang)}
                              sectionId={configUI?.resource ?? sectionIdUrl}
                              lang={lang}
                            />
                          </TabPane>
                        );
                      })}
                    </Tabs>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
