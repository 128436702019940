/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { DataSource } from '../../ms-core/types/status-source-type';
import { FormattedMessage, useIntl } from 'react-intl';
import { DeepMap, ErrorOption, FieldError, FieldValues } from 'react-hook-form';
import { MsType } from '../../ms-core/models/ms-type';
// import { CustomSelect } from '../payment-tx-approval-modal/custom-select';
import './phone-number.scss';
import CustomCreatableSelectWithOnChange from '../custom-creatable-select/custom-creatable-select-with-onchange';

export const PhoneSource: DataSource = {
  '+84': { label: '+84', color: 'primary', value: '+84' },
  '+63': { label: '+63', color: 'primary', value: '+63' },
  '+65': { label: '+65', color: 'primary', value: '+65' },
  '+66': { label: '+66', color: 'primary', value: '+66' },
  '+81': { label: '+81', color: 'primary', value: '+81' },
  '+82': { label: '+82', color: 'primary', value: '+82' },
  '+86': { label: '+86', color: 'primary', value: '+86' },
  '+852': { label: '+852', color: 'primary', value: '+852' },
  '+886': { label: '+886', color: 'primary', value: '+886' },
};

type CFC = React.FC<{
  item: MsType<Record<string, unknown>>;
  errors: DeepMap<FieldValues, FieldError>;
  register?: any;
  setValue?: (name: string, value: any) => void;
  setError: (name: string, error: ErrorOption) => void;
}>;

const PhoneNumber: CFC = ({ register, errors, item, setValue, setError }) => {
  const { formatMessage } = useIntl();

  const [country, setCountry] = useState<string>('+84');
  const [phone, setPhone] = useState<string>();

  useEffect(() => {
    if (!phone) {
      setError(item.field, { type: 'required' });
    }
    setValue?.(item.field, country + phone);
  }, [phone, country]);

  return (
    <div className="field phone-number" key={item.field}>
      <label className="label">
        {item.name && <FormattedMessage id={item.name} />}
        &nbsp;<b className="has-text-danger">{item.required && '*'}</b>
      </label>
      <div className="control is-flex">
        <input
          name={item.field}
          ref={register({
            required: {
              value: item.required ? true : false,
              message: 'This field is required!',
            },
          })}
          type="hidden"
        />
        {/* <CustomSelect
          defaultValue={country}
          name="searchAction"
          options={PhoneSource}
          onChangeData={(e) => {
            setCountry(e);
          }}
          notTranslation
          isNormalSize
          className="phone-number-country"
          disableAllValue
        /> */}
        <CustomCreatableSelectWithOnChange
          defaultValue={country}
          name="searchAction"
          options={PhoneSource}
          handleChange={(e) => {
            setCountry(e);
          }}
          className="phone-number-country"
        />
        <input
          type={item.type}
          name={item.field}
          onChange={(e) => {
            setPhone(e.target.value);
          }}
          placeholder={item.name}
          className="input ml-1"
        />
      </div>
      {errors?.[item?.field] && item.required && (
        <p className="help has-text-danger">
          {errors?.[item?.field].message && <FormattedMessage id={errors?.[item?.field].message} />}
        </p>
      )}
    </div>
  );
};

export default PhoneNumber;
