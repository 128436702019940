import { Pagination } from '../ms-core/models/pagination';

export const sortData = (query: string, items: any) => {
  if (query == null) return items;
  const sortBy = query.split(',');

  if (typeof items[0][sortBy[0]] === 'number') {
    switch (sortBy[1]) {
      case 'asc':
        items.sort((a: any, b: any) => {
          return a?.[sortBy[0]] - b?.[sortBy[0]];
        });
        break;
      case 'desc':
        items.sort((a: any, b: any) => {
          return b?.[sortBy[0]] - a?.[sortBy[0]];
        });
        break;
      default:
        return items;
    }
  } else if (new Date(items[0][sortBy[0]]).getTime() > 0) {
    switch (sortBy[1]) {
      case 'asc':
        items.sort((a: any, b: any) => {
          return a.createdAt.localeCompare(b.createdAt);
        });
        break;
      case 'desc':
        items.sort((a: any, b: any) => {
          return b.createdAt.localeCompare(a.createdAt);
        });
        break;
      default:
        return items;
    }
  }
  return items;
};

export const toLowerCaseLetter = (data: string): string => {
  const temp = data.split('_').join(' ');
  return temp.toLocaleLowerCase();
};

export const toUpperCaseLetter = (data: string): string => {
  const temp = data.split('_').join(' ');
  return temp.toUpperCase();
};

export const capitalizeFirstLetter = (data: string): string => {
  const temp = data.split('_').join(' ');
  return temp[0].toUpperCase() + temp.slice(1).toLocaleLowerCase();
};

export function toTitleCase(str: string, split?: string) {
  if (!str) return '';
  return str
    .split(split ? split : '_')
    .join(' ')
    .replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min) + min);
}

export const intlNumberFmt = (val: any) => Intl.NumberFormat().format(val.toFixed(2));

export function customPagination(data: any): Pagination {
  return {
    number: data.number + 1,
    size: data.size,
    totalElements: data.totalElements,
    totalPages: data.totalPages,
  };
}

export function convertToInternationalCurrencySystem(value: number) {
  return Math.abs(Number(value)) >= 1.0e9
    ? (Math.abs(Number(value)) / 1.0e9).toFixed(0) + 'B'
    : Math.abs(Number(value)) >= 1.0e6
    ? (Math.abs(Number(value)) / 1.0e6).toFixed(0) + 'M'
    : Math.abs(Number(value)) >= 1.0e3
    ? (Math.abs(Number(value)) / 1.0e3).toFixed(0) + 'K'
    : Math.abs(Number(value));
}

export function fGenerateUniqueID(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function mergeConsecutiveElementsArray(array: any[], size: number, offsetData?: number) {
  let offset = offsetData ?? 0;
  const result = [];
  while (offset < array.length) {
    result.push(array.slice(offset, (offset += size)));
  }
  return result;
}

export function ExcelDateToJSDate(serial: number) {
  const utc_days = Math.floor(serial - 25569);
  const utc_value = utc_days * 86400;
  const date_info = new Date(utc_value * 1000);

  const fractional_day = serial - Math.floor(serial) + 0.0000001;

  let total_seconds = Math.floor(86400 * fractional_day);

  const seconds = total_seconds % 60;

  total_seconds -= seconds;

  const hours = Math.floor(total_seconds / (60 * 60));
  const minutes = Math.floor(total_seconds / 60) % 60;

  return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
}

export function removeDuplicates(array: Record<string, any>, fieldCheck: string) {
  return array.filter(
    (v: Record<string, any>, i: number, a: Record<string, any>[]) =>
      a.findIndex((v2) => v2![fieldCheck] === v![fieldCheck]) === i,
  );
}

export function formatHtml(html: string) {
  const tab = '\t';
  let result = '';
  let indent = '';

  html?.split(/>\s*</).forEach(function (element: string) {
    if (element.match(/^\/\w/)) {
      indent = indent.substring(tab.length);
    }

    result += indent + '<' + element + '>\r\n';

    if (element.match(/^<?\w[^>]*[^\/]$/) && !element.startsWith('input')) {
      indent += tab;
    }
  });

  return result.substring(1, result.length - 3);
}

export function isEmpty(value: any): boolean {
  return (
    value == null ||
    (value.hasOwnProperty('length') && value.length === 0) ||
    (value.constructor === Object && Object.keys(value).length === 0)
  );
}

export function arrayMove(arr: any[], oldIndex: number, newIndex: number) {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
}

export const f3digits = (amt?: number) => Intl.NumberFormat().format(Math.floor(amt || 0));

export const getClassNameWithAmt = (amt?: number) => {
  if (!amt || amt === 0) {
    return '';
  } else if (amt > 0) {
    return 'has-text-success has-background-success-light';
  } else {
    return 'has-text-danger has-background-danger-light';
  }
};

export const convertToSlug = (text: string, categoryId: string): string =>
  `/${categoryId ? `${categoryId}/` : ''}${text
    .toLowerCase()
    .replace(/[^\w\s]/g, '')
    .trim()
    .replace(/\s+/g, '-')}`;
