/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
/*eslint @typescript-eslint/no-unused-vars: 0 */
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { PaginationLimits } from '../../constants/types/pagination-limits';
import './ms-pagination.scss';

export type Props = {
  onSearchChanged: (params: Record<string, unknown>) => void;
  page?: number;
  size?: number;
  numberOfElements: number;
  getTotal?: () => Promise<number>;
};

const MsOffsetPagination: React.FunctionComponent<Props> = ({
  onSearchChanged,
  page,
  size,
  numberOfElements,
  getTotal,
}) => {
  const { formatMessage } = useIntl();

  const curPage = page || 1;
  const curSize = size || 15;
  const [pageInput, setPageInput] = useState(curPage);
  const [total, setTotal] = useState<number>();
  const [loadingTotal, setLoadingTotal] = useState(false);

  const from = curSize * (curPage - 1) + 1;
  const to = from + numberOfElements - 1;
  const hasPrevPage = curPage > 1;
  const hasNextPage = numberOfElements >= curSize;

  function handleSelectLimit(e: React.ChangeEvent<HTMLSelectElement>) {
    e.preventDefault();
    onSearchChanged({
      size: e.target.value,
    });
  }

  function onPageChanged(page: number) {
    onSearchChanged({
      page,
    });
  }

  function onGetTotal() {
    setLoadingTotal(true);
    getTotal?.()
      .then((total) => setTotal(total))
      .finally(() => setLoadingTotal(false));
  }

  return (
    <div className="level">
      <div className="level-left" />

      <div className="level-right">
        <div className="level-item">
          <nav className="pagination is-centered is-small" role="navigation" aria-label="pagination">
            <div className="control has-icons-left">
              <div className="select is-small">
                <select value={size} onChange={handleSelectLimit}>
                  <option value={''}>
                    -- {formatMessage({ id: 'Pagination' })}
                    --
                  </option>
                  {PaginationLimits.map((limit) => (
                    <option key={limit} value={limit}>
                      {limit}&nbsp;
                      {formatMessage({ id: 'records' })}
                    </option>
                  ))}
                </select>
              </div>
              <span className="icon is-small is-left">
                <i className="mdi mdi-label-multiple" />
              </span>
            </div>
            <button className="pagination-previous" onClick={() => onPageChanged(1)} disabled={!hasPrevPage}>
              <i className="mdi mdi-page-first" />
            </button>
            <button className="pagination-previous" onClick={() => onPageChanged(curPage - 1)} disabled={!hasPrevPage}>
              <i className="mdi mdi-chevron-left" />
            </button>
            <button className="pagination-next" onClick={() => onPageChanged(curPage + 1)} disabled={!hasNextPage}>
              <i className="mdi mdi-chevron-right" />
            </button>
            <button className="pagination-next" onClick={() => onGetTotal()} disabled={!hasNextPage || loadingTotal}>
              <FormattedMessage id="of" />
              &nbsp;
              {total != null
                ? Intl.NumberFormat('en').format(total)
                : `${Intl.NumberFormat('en').format(to)} ${hasNextPage ? '+' : ''}`}
            </button>

            <ul className="pagination-list">
              <li>
                <button className="pagination-link">
                  {from}-{to}
                </button>
              </li>
            </ul>
          </nav>
        </div>

        <div className="level-item MS_Pagination">
          <form
            onSubmit={(event) => {
              event.preventDefault();
              onPageChanged(pageInput);
            }}>
            <div className="field has-addons">
              <div className="control">
                <input
                  type="number"
                  placeholder={formatMessage({ id: 'Jump to page...' })}
                  className="input is-small"
                  value={pageInput}
                  onChange={(event) => setPageInput(Number(event.target.value))}
                />
              </div>
              <div className="control">
                <button type="submit" className="button is-small">
                  <FormattedMessage id="Go" />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MsOffsetPagination;
