import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useModal } from '../../../ms-core/hook/use-modal';
import { showToast } from '../../../ms-core/utils/toast';
import { MS_API } from '../../../ms-core/utils/api';
import FormControl, { FieldConfirm } from '../FormControl';
import { MsType } from '../../../ms-core/models/ms-type';

interface Props {
  conf?: MsType[];
  title?: string;
  resourceEndpoint?: string;
  method?: 'post' | 'put' | 'patch' | 'delete';
  refreshData?: (item?: Record<string, any>) => void;
  className?: string;
  rightHeader?: string;
  icon?: string;
  tooltip?: string;
  confirmField?: FieldConfirm;
  editFrom?: {
    defaultValues?: Record<string, unknown>;
    fetchData?: {
      doEdit?: (value: Record<string, unknown>) => void;
      id?: string;
    };
  };
  buttonText?: boolean;
  closeModal?: boolean; //Push is-loading in here
}

const AxiosDeleteWithBody = async (
  url: string,
  data: Record<string, any>,
  params = {},
  customParameter = { showPopup: true },
) => {
  try {
    const config = {
      data,
      params,
      customParameter,
    };

    const response = await MS_API.delete(url, config);
    if (!response.data.error) return { data: response.data, error: null };
    else {
      return { data: response, error: null };
    }
  } catch (error) {
    return { ...(error as any), data: null };
  }
};

const FormControlModal = (props: Props) => {
  const { showModal, onHide } = useModal();

  const { formatMessage } = useIntl();

  const {
    conf,
    title,
    resourceEndpoint,
    editFrom,
    refreshData,
    className,
    method,
    rightHeader,
    icon,
    tooltip,
    confirmField,
    buttonText,
    closeModal,
  } = props;

  const onClick = () => {
    showModal(
      <FormSubmitControlModal
        onHide={onHide}
        conf={conf}
        title={title}
        rightHeader={rightHeader}
        confirmField={confirmField}
        resourceEndpoint={resourceEndpoint}
        method={method}
        refreshData={refreshData}
        editFrom={editFrom}
      />,
      false,
    );
  };

  useEffect(() => {
    onHide();
    /**
     * TODO
     * Disabled dependency because bug not click
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeModal]);

  return (
    <>
      <button
        type="button"
        className={`button has-tooltip-arrow ${className ? className : 'is-primary'} ${buttonText ? 'is-small' : ''}`}
        onClick={onClick}>
        <span className="icon" data-tooltip={formatMessage({ id: tooltip ? tooltip : title })}>
          <i className={`mdi ${buttonText ? '' : 'mdi-18px'}  mdi-${icon ? icon : 'square-edit-outline'}`} />
        </span>
        {buttonText && title && (
          <span>
            <FormattedMessage id={title} />
          </span>
        )}
      </button>
    </>
  );
};

export default FormControlModal;

export type FormSubmitControlModalProps = {
  isLoading?: boolean;
  onHide: () => void;
  conf?: MsType[];
  title?: string;
  resourceEndpoint?: string;
  method?: 'post' | 'put' | 'patch' | 'delete';
  refreshData?: (item?: Record<string, any>) => void;
  className?: string;
  rightHeader?: string;
  confirmField?: FieldConfirm;
  editFrom?: {
    defaultValues?: Record<string, unknown>;
    fetchData?: {
      doEdit?: (value: Record<string, unknown>) => void;
      id?: string;
    };
  };
};

export const FormSubmitControlModal = (props: FormSubmitControlModalProps) => {
  const [isLoading, setLoading] = useState<boolean>(props.isLoading ? props.isLoading : false);
  const { conf, title, resourceEndpoint, editFrom, refreshData, method, rightHeader, confirmField, onHide } = props;

  const doEditResource = (formData: Record<string, unknown>): Promise<void> => {
    setLoading(true);
    const requestMethod = method === 'delete' ? AxiosDeleteWithBody : MS_API?.[method || 'post'];

    const url = `${resourceEndpoint}${editFrom?.fetchData?.id ? '/' + editFrom.fetchData.id : ''}`;

    return requestMethod(url, formData)
      .then(() => {
        refreshData?.(formData);
        onHide();
        showToast.success('Update resource success!');
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <FormControl
        isLoading={isLoading}
        closeModal={onHide}
        conf={conf}
        onSubmitData={editFrom?.fetchData?.doEdit ? editFrom?.fetchData?.doEdit : doEditResource}
        defaultValues={editFrom?.defaultValues}
        title={title}
        rightHeader={rightHeader}
        confirmField={confirmField}
      />
    </>
  );
};
