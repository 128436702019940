import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { bootstrapApplication } from './app.bootstrap';
// import { defineCustomElements } from '@ionic/pwa-elements/loader';

bootstrapApplication('en').then((app) => {
  ReactDOM.render(app, document.getElementById('root'));
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Call the element loader after the platform has been bootstrapped
// defineCustomElements(window);
