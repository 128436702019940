import _ from 'lodash';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import Tabs from '../../../../../../../components/tab/Tab';
import TabPane from '../../../../../../../components/tab/TabPanel/tab-panel';
import { MsIndex } from '../../../../../../../ms-core/blocks/ms-index';
import { useConfirm } from '../../../../../../../ms-core/hook/use-confirm';
import { showToast } from '../../../../../../../ms-core/utils/toast';
import { toTitleCase } from '../../../../../../../utils/helper';
import { SectionDynamic } from '../../section-dynamic.builder';
import { DetailButton } from './components';
import { ObjectJson } from '../object-json';
import { IArrayJsonProp } from './array-json.type';
import { PriorityPositionButton } from './components';

export const ArrayJson: React.FC<IArrayJsonProp> = (props) => {
  const {
    sectionId,
    lang,
    configs,
    isLoading,
    items,
    response,
    onUpdateData,
    onRefresh,
    parentConfigs,
    parentItem,
    dataOfObjectHasArray,
  } = props;

  const { showConfirm } = useConfirm();

  const onSubmitUpdateArrayData = useCallback(
    async (values: Record<string, any>, callBack?: () => void) => {
      await SectionDynamic.setDataSubmit(values);
      await onUpdateData(
        {
          ...values,
        },
        callBack,
      );
    },
    [onUpdateData],
  );

  const saveArrayData = useCallback(
    (values: Record<string, any>, callBack?: () => void) => {
      const tmpData: Record<string, any> = {};
      /**
       * TODO: Handle data for ArrayJson
       */
      if (parentConfigs?.nameKey) {
        if (Array.isArray(parentItem)) {
          const originalArray = parentItem.map((item) =>
            item?.id === dataOfObjectHasArray?.id ? { ...dataOfObjectHasArray, ...values } : item,
          );
          _.set(tmpData, [parentConfigs?.nameKey], originalArray);
          onSubmitUpdateArrayData(tmpData, callBack);
        } else {
          onSubmitUpdateArrayData(values, callBack);
        }
      } else {
        onSubmitUpdateArrayData(values, callBack);
      }
    },
    [dataOfObjectHasArray, onSubmitUpdateArrayData, parentConfigs?.nameKey, parentItem],
  );

  const onSubmitUpdateObjectData = useCallback(
    (values: Record<string, any>, data: Record<string, any>, callBack?: () => void) => {
      const tmpData: Record<string, any> = {};
      if (configs?.nameKey) {
        const originalArray = items.map((item) => (item.id === data.id ? { ...data, ...values } : item));
        _.set(tmpData, [configs?.nameKey], originalArray);

        onSubmitUpdateArrayData(tmpData, callBack);
      } else {
        onSubmitUpdateArrayData({ ...response, ...values }, callBack);
      }
    },
    [configs?.nameKey, items, onSubmitUpdateArrayData, response],
  );

  const onDeleteData = useCallback(
    (id: string) => {
      if (!id) {
        showToast.error('Invalid id');
        return;
      }

      if (parentConfigs?.nameKey) {
        const tmpData: Record<string, any> = {};

        if (Array.isArray(parentItem)) {
          const newItemAfterRemove = dataOfObjectHasArray?.[configs?.nameKey]?.filter(
            (i: Record<string, any>) => `${i.id}` !== `${id}`,
          );

          const originalArray = parentItem.map((item) =>
            `${item?.id}` === `${dataOfObjectHasArray?.id}`
              ? {
                  ...dataOfObjectHasArray,
                  [configs?.nameKey]: newItemAfterRemove,
                }
              : item,
          );

          _.set(tmpData, [parentConfigs?.nameKey], originalArray);

          onSubmitUpdateArrayData(tmpData);
        } else {
          const tmpData: Record<string, any> = {};
          const newData = items?.filter((item) => `${item.id}` !== `${id}`);
          _.set(tmpData, [parentConfigs?.nameKey], [{ [configs?.nameKey]: newData }]);
          onSubmitUpdateArrayData({
            ...{ [configs?.nameKey]: newData },
          });
        }
      } else {
        const newData = items?.filter((item) => `${item.id}` !== `${id}`);
        onSubmitUpdateArrayData({
          ...{ [configs?.nameKey]: newData },
        });
      }
    },
    [configs?.nameKey, dataOfObjectHasArray, items, onSubmitUpdateArrayData, parentConfigs?.nameKey, parentItem],
  );

  const onConfirmDelete = useCallback(
    (id: string) => {
      showConfirm('Delete', 'Accept', {
        onConfirm: () => onDeleteData(id),
      });
    },
    [onDeleteData, showConfirm],
  );

  return (
    <MsIndex
      expanded={Array.isArray(configs?.configs)}
      listRequested={isLoading}
      conf={
        configs?.listConf
          ? [
              {
                name: '',
                field: 'actionDetail',
                type: 'detail',
                displayType: 'customizing',
                customizeIndexData: (_, data) => data,
                customizeIndexDisplay: (item, _, e) => (
                  <td key="detail" className="has-text-centered is-narrow is-overflow-visible">
                    <DetailButton
                      conf={configs?.listConf}
                      items={items}
                      item={item}
                      response={response}
                      nameKey={configs?.nameKey}
                      onUpdateData={saveArrayData}
                      isLoading={isLoading}
                      configs={configs}
                    />
                    <PriorityPositionButton
                      item={item}
                      items={items}
                      nameKey={configs?.nameKey}
                      onUpdateData={saveArrayData}
                      isLoading={isLoading}
                    />
                  </td>
                ),
                customizeExpandedRowRender: (data) => {
                  return (
                    <>
                      <Tabs hiddenTabName={configs?.hiddenTabName ?? false}>
                        {Array.isArray(configs?.configs) &&
                          configs?.configs?.map((cfg: Record<string, any>, key: number) => {
                            if (cfg.type === 'array') {
                              return (
                                <TabPane name={cfg.title} key={key + 1}>
                                  <ArrayJson
                                    sectionId={sectionId}
                                    lang={lang}
                                    configs={cfg}
                                    onRefresh={() => onRefresh?.()}
                                    response={response}
                                    items={_.get(data, cfg.nameKey || '', []) as Record<string, any>[]}
                                    onUpdateData={saveArrayData}
                                    isLoading={isLoading}
                                    parentConfigs={configs}
                                    parentItem={items}
                                    dataOfObjectHasArray={data}
                                  />
                                </TabPane>
                              );
                            } else {
                              return (
                                <TabPane name={cfg.title} key={key + 1}>
                                  <ObjectJson
                                    onUpdateData={(v, c) => onSubmitUpdateObjectData(v, data, c)}
                                    isLoading={isLoading}
                                    conf={cfg.listConf}
                                    response={response}
                                    nameKey={cfg.nameKey}
                                    item={_.get(data, cfg.nameKey || '', {}) as Record<string, any>}
                                    title={cfg?.title ?? toTitleCase(sectionId ?? '-', '-')}
                                    parentConfigs={configs}
                                    configs={cfg}
                                    parentItem={data}
                                    sectionId={sectionId}
                                    lang={lang}
                                    onRefresh={() => onRefresh?.()}
                                  />
                                </TabPane>
                              );
                            }
                          })}
                      </Tabs>
                    </>
                  );
                },
              },
              ...[
                {
                  field: 'id',
                  name: 'Id',
                  type: 'id',
                },
                ...configs?.listConf,
              ],
              {
                name: ' ',
                field: 'action',
                type: 'customizing',
                customizeIndexData: (_, data) => data,
                customizeIndexDisplay: (data, _, e) => {
                  return (
                    <td key="action" className="has-text-right is-narrow is-overflow-visible">
                      <button className="button is-danger ml-2" onClick={() => onConfirmDelete(data.id)}>
                        <span className="icon">
                          <i className="mdi mdi-18px mdi-delete-alert" />
                        </span>
                      </button>
                    </td>
                  );
                },
              },
            ]
          : []
      }
      items={items}
      title={configs?.title}
      iconTitle={`alpha-${configs?.title?.split('')[0].toLowerCase()}-circle`}
      renderRightHeader={() => {
        return (
          <>
            {isLoading ? (
              <Skeleton count={1} />
            ) : (
              <button onClick={() => onRefresh?.()} type="button" className="button is-small">
                <span className="icon">
                  <i className="mdi mdi-refresh default" />
                </span>
                <span>
                  <FormattedMessage id="Refresh data" />
                </span>
              </button>
            )}
            <DetailButton
              conf={configs?.listConf}
              items={items}
              isCreate
              response={response}
              nameKey={configs?.nameKey}
              onUpdateData={(v, c) => saveArrayData(v, c)}
              isLoading={isLoading}
              configs={configs}
            />
          </>
        );
      }}
    />
  );
};
