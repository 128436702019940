import React, { memo, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  name: string;
  label?: string;
  mbNumber?: number;
  register: any;
  value: boolean;
  setValue?: (name: string, value: boolean) => void;
  handChange?: (value: boolean) => void;
  turnOff?: boolean;
  disabled?: boolean;
}
const SwitchButton: React.FC<Props> = memo((props: Props) => {
  const { name, register, setValue, value, label, mbNumber, turnOff, disabled, handChange } = props;
  const [checked, setChecked] = useState(false);

  const onChange = (checked: boolean) => {
    setChecked(checked);
    setValue?.(name, checked);
    handChange?.(checked);
  };

  useEffect(() => {
    register({ name });
  }, [name, register]);

  useEffect(() => {
    if (value !== checked) {
      setChecked(value);
      setValue?.(name, value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, value, setChecked, setValue]);

  return (
    <div
      className="switch-button-wrapper  "
      style={{ marginBottom: mbNumber, width: '200px', opacity: disabled ? '0.7' : '1' }}>
      <div className="field">
        <input name={name} type="hidden" />
        <div className="control">
          <label className="switch">
            <input
              name={name}
              ref={register}
              id={name}
              type="checkbox"
              className="switch is-rounded"
              checked={checked}
              onChange={(e) => onChange(e.target.checked)}
              disabled={disabled}
            />
            <span className={`check is-${turnOff ? 'success' : 'danger'}`} />
            <span className="control-label">{label && <FormattedMessage id={label} />}</span>
          </label>
        </div>
      </div>
    </div>
  );
});

SwitchButton.defaultProps = {
  mbNumber: 0,
  value: false,
};

export default SwitchButton;
