/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import Select from 'react-select';
import { CSSObject } from '@emotion/serialize';
import { useIntl } from 'react-intl';
import { useController } from 'react-hook-form';
import { debounce } from 'lodash';

type CFC = React.FC<
  Parameters<typeof useController>[0] & {
    label?: string;
    name: string;
    options: string;
    defaultValue: string;
    placeholder?: string;
    className?: string;
    errors?: boolean;
    handleChange?: (e: string) => void;
    isMulti?: boolean;
    disabled?: boolean;
    getArray?: boolean;
  }
>;
export type IDataSourSelect = {
  value: string;
  label: string;
};
const MultiSelect: CFC = ({
  options,
  name,
  control,
  rules,
  placeholder,
  defaultValue,
  label,
  errors,
  isMulti,
  disabled,
  getArray,
}) => {
  const { formatMessage } = useIntl();
  const [optionsData, setOptionsData] = useState<IDataSourSelect[]>([]);
  const [value, setValue] = useState<IDataSourSelect[]>([]);

  const customStylesNormal = {
    valueContainer: (provided: CSSObject) => ({
      ...provided,
      padding: '0 6px',
    }),
    option: (provided: CSSObject) => ({
      ...provided,
      padding: '0 6px',
    }),
    control: (base: CSSObject) => ({
      ...base,
      borderColor: errors ? 'red' : 'hsl(0,0%,80%)',
      boxShadow: errors ? 'red' : 'hsl(0,0%,80%)',
    }),
  };
  const {
    field: { onChange },
  } = useController({ name, rules, control });

  const convertStringToOption = (options: string): IDataSourSelect[] => {
    const data: IDataSourSelect[] = [];
    options &&
      options?.split(',').forEach((item: any) =>
        data.push({
          label: item,
          value: item,
        }),
      );
    return data;
  };

  function combineValue(data: IDataSourSelect[]): void {
    const dataSubmit: string[] = [];
    data.forEach((item) => {
      item?.value && dataSubmit.push(item.value ?? '');
    });
    setValue(data);
    onChange(getArray ? dataSubmit : dataSubmit.join(','));
  }

  function extractValue(value: IDataSourSelect[], options: IDataSourSelect[]): IDataSourSelect[] {
    const data: IDataSourSelect[] = options;
    value.forEach((item: IDataSourSelect) => {
      data.splice(data[options.findIndex((e) => e.value === item.value)] as unknown as number, 1);
    });
    return data;
  }
  useEffect(() => {
    defaultValue && setValue(convertStringToOption(defaultValue));
  }, [defaultValue]);
  useEffect(() => {
    setOptionsData(extractValue(value, convertStringToOption(options)));
  }, [options]);
  const delayed = useRef(debounce((e, func) => func(e), 100)).current;
  const search = useRef(
    debounce((value) => {
      setOptionsData([...optionsData, { value: value, label: value }]);
    }, 300),
  );
  const handleOnchange = (value: string) => {
    search.current(value);
  };
  return (
    <div className={`selection-control `}>
      {label && (
        <label className="label">
          {label} <b className="has-text-danger">*</b>
        </label>
      )}
      <div className={`field`}>
        <Select
          closeMenuOnSelect={false}
          isDisabled={disabled}
          value={value}
          className="is-fullwidth"
          isMulti={isMulti}
          onChange={(option) => {
            combineValue(option as IDataSourSelect[]);
          }}
          onInputChange={(option) => {
            handleOnchange(option);
          }}
          options={optionsData}
          placeholder={placeholder ? formatMessage({ id: placeholder }) : ''}
          styles={customStylesNormal as any}
          menuPosition={'fixed'}
        />
      </div>
    </div>
  );
};

export default MultiSelect;
