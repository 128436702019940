/* eslint-disable @typescript-eslint/no-throw-literal */
/* eslint-disable no-underscore-dangle */
/* eslint-disable symbol-description */
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { UNAUTHORIZED } from '../const/http-status';
import { LoginDto } from './dto/login-dto';
import { API_ENDPOINT } from '../../constants/api-endpoint';
import { Staff } from '../../constants/model/staff';
import { showToast } from './toast';
// import { BASE_URL } from '../../utils/secrets';

const singleton = Symbol();
const singletonEnforcer = Symbol();

class ApiService {
  session: AxiosInstance;

  constructor(enforcer: typeof singletonEnforcer) {
    if (enforcer !== singletonEnforcer) {
      throw new Error('Cannot construct singleton');
    }

    this.session = axios.create({
      baseURL: '/',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    });

    this.session.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error: { config: any; response: { status: any; data: any } }) => {
        const originalRequest = error.config;
        if (!originalRequest?._retry) {
          switch (error.response ? error.response.status : null) {
            case UNAUTHORIZED:
              originalRequest._retry = true;
              window.localStorage.removeItem('access_token');
              window.location.reload();
              if (error.response?.status) {
                showToast.errorCode(401);
              }
              // return originalRequest object with currently session
              // return this.session.post('/auth/token', { "refresh_token": this.refreshToken })
              //   .then(res => {
              //     if (res.status === 201) {
              //       this.resetSession(res.data);
              //       return this.session(originalRequest);
              //     }
              //   });
              break;
            default:
              if (error.response?.data?.code) {
                showToast.errorCode(error.response.data.code);
              } else {
                showToast.errorCode('default_error');
              }

              throw error;
          }
        }

        throw error;
      },
    );
  }

  static get instance(): ApiService {
    // Try to get an efficient singleton
    if (!(this as any)[singleton]) {
      (this as any)[singleton] = new ApiService(singletonEnforcer);
    }

    return (this as any)[singleton];
  }

  authorization(token: string) {
    this.session.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  }

  options: AxiosInstance['options'] = (...params) => this.session.options(...params);

  get: AxiosInstance['get'] = (...params) => this.session.get(...params);

  post: AxiosInstance['post'] = (...params) => this.session.post(...params);

  put: AxiosInstance['put'] = (...params) => this.session.put(...params);

  delete: AxiosInstance['delete'] = (...params) => this.session.delete(...params);

  patch: AxiosInstance['patch'] = (...params) => this.session.patch(...params);

  login(username: string, password: string, captcha: string, otp: string): Promise<AxiosResponse<{ data: LoginDto }>> {
    return this.post<{ data: LoginDto }>(
      API_ENDPOINT.LOGIN,
      {
        email: username,
        password,
      },
      {
        headers: { 'Content-Type': 'application/json' }, // Thay đổi loại content
      },
    );
  }

  me(): Promise<AxiosResponse<Staff>> {
    return this.get<Staff>('/user/me');
  }
}

export const MS_API = ApiService.instance;
