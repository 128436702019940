import React from 'react';
import { formatCurrencies } from '../../utils/card-money';

interface Props {
  className?: string;
  data: number;
  negativeColor?: string;
  positiveColor?: string;
  addon?: string;
  children?: React.ReactNode;
  minNumber?: number;
  currencyDecimal?: number;
}

function GGMoneyCell(props: Props) {
  return (
    <td
      className={`has-text-right has-text-weight-semibold has-text-${
        props.data > (!!props.minNumber ? props.minNumber : 0)
          ? props.positiveColor ?? 'success'
          : props.negativeColor ?? 'danger'
      } ${props.className}`}>
      {isNaN(props.data) ? '-' : formatCurrencies.BCOIN(Number(props.data || ''), props?.currencyDecimal)}
      {props.addon}
      {props.children}
    </td>
  );
}

export default GGMoneyCell;
