/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useModal } from '../../ms-core/hook/use-modal';
import { copyToClipboard } from '../../ms-core/utils/copy';
import { showToast } from '../../ms-core/utils/toast';
import { TYPE_FILE, MAX_SIZE_OF_FILE } from '../../utils/secrets';
import { ImgServer } from '../image-server';
import { ScrollBarControl } from '../scroll-bar-control';
import { adminUploadImage } from '../../services/account/common';

type ModalUploadFileProps = {
  folderName?: string;
  isHasAccessPublic?: boolean;
};

export function ModalUploadFile(props: ModalUploadFileProps) {
  const { showModal } = useModal();

  const onClick = () => {
    showModal(<ModalUploadFileForm folder={props.folderName} isHasAccessPublic={props.isHasAccessPublic} />);
  };

  return (
    <>
      <button type="button" className="button is-small is-primary" onClick={onClick}>
        <span className="icon">
          <i className="mdi mdi-cloud-upload default" />
        </span>
        <span>
          <FormattedMessage id="Upload Image" />
        </span>
      </button>
    </>
  );
}

type ModalUploadFileFormProps = {
  onChange?: (e: string) => void;
  hiddenCopy?: boolean;
  onHide?: () => void;
  isHasCard?: boolean;
  defaultValue?: string;
  folder?: string;
  isHasAccessPublic?: boolean;
  fileName?: string;
  fileType?: 'image' | 'video';
  type?: string[];
  size?: number;
};

export function ModalUploadFileForm(props: ModalUploadFileFormProps) {
  const {
    isHasCard = true,
    folder,
    isHasAccessPublic = false,
    type = TYPE_FILE,
    size = MAX_SIZE_OF_FILE,
    fileName,
    fileType = 'image',
  } = props;

  const [isLoading, setLoading] = useState<boolean>(false);

  const [file, setFile] = useState<File>();

  const [previewFile, setPreviewFile] = useState<any>();

  const [errorUpload, setErrorUpload] = useState(false);

  const [urlImage, setUrlImage] = useState<string>('');

  const [isCopySuccess, setIsCopySuccess] = useState<boolean>(true);

  const [folderNameState, setFolderNameState] = useState<string>();

  const [fileNameState, setFileNameState] = useState<string>();

  const getBase64 = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setErrorUpload(false);
      setPreviewFile(reader.result);
    };
  };

  const uploadFile = (file: File) => {
    setLoading(true);

    if (!!file && !!folderNameState) {
      adminUploadImage(file, {
        fileName: fileNameState,
        fileType,
      })
        .then((res) => {
          setPreviewFile(res);
          setUrlImage(res);
          props?.onChange?.(res);
          props.hiddenCopy && props?.onHide?.();
        })
        .finally(() => setLoading(false));
    }
  };

  const onSubmitUpload = () => {
    if (file) {
      uploadFile(file);
      setErrorUpload(false);
    } else {
      setErrorUpload(true);
    }
  };

  useEffect(() => {
    setPreviewFile(props.defaultValue);
    props?.defaultValue && setUrlImage(props.defaultValue);
  }, [props.defaultValue]);

  useEffect(() => {
    folder && setFolderNameState(folder);
    fileName && setFileNameState(fileName);
  }, [folder, fileName]);

  const returnFormUpload = () => {
    return (
      <>
        {urlImage && !props.hiddenCopy && (
          <>
            <div className="file has-name is-fullwidth is-right is-small">
              <label className="file-label">
                <span className="file-cta">
                  <span className="file-label">
                    <span
                      className="icon-text is-clickable has-text-success"
                      onClick={async () => {
                        // const result = await copyToClipboard(
                        //   isHasAccessPublic
                        //     ? (('/assets/public' +
                        //         urlImage.match(
                        //           new RegExp(`\\/${folderNameState}\\/(?<=\\/)[^\\/\\?#]+(?=[^\\/]*$)`, 'i'),
                        //         )?.[0]) as string)
                        //     : (urlImage.match(
                        //         new RegExp(`\\/${folderNameState}\\/(?<=\\/)[^\\/\\?#]+(?=[^\\/]*$)`, 'i'),
                        //       )?.[0] as string),
                        // );
                        /**
                         * TODO: Handle nginx
                         */
                        const result = await copyToClipboard(urlImage);

                        if (result) {
                          setIsCopySuccess(true);
                          showToast.success('Copied');
                        } else {
                          setIsCopySuccess(false);
                          showToast.warning('Cannot copy, please copy manual!');
                        }
                      }}>
                      <span className="icon is-primary">
                        <i className="mdi mdi-content-copy" />
                      </span>
                    </span>
                  </span>
                </span>
                <span
                  className="file-name"
                  style={{
                    maxWidth: '300px',
                  }}>
                  {/* {isHasAccessPublic
                    ? (('/assets/public' +
                        urlImage.match(
                          new RegExp(`\\/${folderNameState}\\/(?<=\\/)[^\\/\\?#]+(?=[^\\/]*$)`, 'i'),
                        )?.[0]) as string)
                    : (urlImage.match(
                        new RegExp(`\\/${folderNameState}\\/(?<=\\/)[^\\/\\?#]+(?=[^\\/]*$)`, 'i'),
                      )?.[0] as string)} */}
                  {previewFile}
                </span>
              </label>
            </div>
            {!isCopySuccess && (
              <textarea
                disabled
                value={urlImage}
                className="textarea mt-2 has-text-weight-bold"
                placeholder=""
                rows={3}
              />
            )}
          </>
        )}
        <div className="field">
          <div className="control">
            <div className="field">
              <label className="label" />
              <div className="control">
                <ScrollBarControl id="upload" style={{ maxHeight: '350px' }}>
                  <ImgServer isLoading={isLoading} className="image-banner" src={previewFile} alt="" />
                </ScrollBarControl>
              </div>
            </div>
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <div className={`file is-small has-name${errorUpload ? ' is-danger' : ''}`}>
                    <label className="file-label">
                      <input
                        type="file"
                        className="file-input"
                        accept={type.join(',')}
                        onChange={(e) => {
                          const file = e.target.files?.[0];
                          if (!!file && type.includes(file.type) && file.size <= size) {
                            setFile(file);
                            getBase64(file);
                          } else {
                            showToast.warn('Invalid uploaded image format');
                          }
                        }}
                      />
                      <span className="file-cta">
                        <span className="file-icon">
                          <i className="fas fa-upload" />
                        </span>
                        <span className="file-label">
                          <FormattedMessage id="Choose a file…" />
                        </span>
                      </span>
                      <span className="file-name">{file?.name ?? <FormattedMessage id="No file chose" />}</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="level-right ml-4">
                <div className="level-item">
                  <div
                    onClick={() => onSubmitUpload()}
                    className={`button is-small is-primary ${isLoading ? ' is-loading' : ''}`}>
                    <span className="icon">
                      <i className="mdi mdi-cloud-upload default" />
                    </span>
                    <span>
                      <FormattedMessage id="Upload Image" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      {isHasCard ? (
        <div className="card modal-upload-image">
          <ScrollBarControl id="load-image" style={{ maxHeight: '95vh' }}>
            <header className="card-header">
              <p className="card-header-title p-3">
                <FormattedMessage id="Upload Image" />
              </p>
            </header>
            <div className="card-content p-0">
              <div className="column ">
                <div className="column is-full">{returnFormUpload()}</div>
              </div>
            </div>
          </ScrollBarControl>
        </div>
      ) : (
        returnFormUpload()
      )}
    </>
  );
}
