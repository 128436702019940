import React, { useContext, createContext, useEffect, useState } from 'react';
import { Staff } from '../../constants/model/staff';
import { MS_API } from './api';
// import { getAdminUIConfigById } from '../../services/common/admin-builders';

const authContext = createContext({});

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }: any) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object and update when it changes.
export const useAuth = () => useContext(authContext) as ReturnType<typeof useProvideAuth>;

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [accessToken, setAccessToken] = useState(window.localStorage.getItem('access_token'));

  const [staff, setStaff] = useState(null as Staff | null);

  const signin = async (email: string, password: string, captcha: string, otp: string): Promise<void> => {
    const loginRes = await MS_API.login(email, password, captcha, otp);

    if (loginRes.data.data.accessToken) {
      MS_API.authorization(loginRes.data.data.accessToken);
      window.localStorage.setItem('access_token', loginRes.data.data.accessToken);

      setAccessToken(loginRes.data.data.accessToken);
    }
  };

  const signOut = () => {
    window.localStorage.removeItem('access_token');
    // window.localStorage.removeItem('general-config');
    setAccessToken(null);
  };

  // Get user on mount
  useEffect(() => {
    const token = window.localStorage.getItem('access_token');
    if (token) {
      MS_API.authorization(token);
      setAccessToken(token);
    }
  }, []);

  useEffect(() => {
    if (accessToken) {
      // MS_API.me().then((res) => {
      //   setStaff(res.data);
      // });
      // getAdminUIConfigById('en', 'general-config').then((res) => {
      //   window.localStorage.setItem('general-config', JSON.stringify(res ?? {}));
      // });
      // getAdminUIConfigById('en', 'ui-config').then((res) => {
      //   window.localStorage.setItem('ui-config', JSON.stringify(res ?? {}));
      // });
    }
  }, [accessToken]);

  return {
    accessToken,
    staff,
    signin,
    signOut,
  };
}
