import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Control, Controller, FieldValues } from 'react-hook-form';
import NumberFormat from 'react-number-format';

type Props = {
  name: string[];
  control: Control<FieldValues>;
  classNameColumn?: string;
  onChange?: (value: string[]) => void;
  disableAllValue?: boolean;
  setValue?: (name: string, value: any) => void;
  defaultValue?: string[];
  translation?: boolean;
  placeholder?: string;
};

export function InputRange(props: Props) {
  const { formatMessage } = useIntl();
  const { name, defaultValue, control, translation = true, onChange, placeholder, setValue } = props;
  const [load, loaded] = useState(false);

  useEffect(() => {
    if (defaultValue?.[0] === undefined) {
      setValue?.(name[0], '');
    }
    if (defaultValue?.[1] === undefined) {
      setValue?.(name[1], '');
    }
  }, [defaultValue]);

  return (
    <div className={`column is-narrow  ${props.classNameColumn}`}>
      <div className="columns">
        <div className="column is-narrow">
          <div
            className="is-flex is-align-items-center"
            style={{
              borderRadius: '2px',
              fontSize: '0.75rem',
              border: '1px solid #dbdbdb',
              minWidth: 300,
              maxWidth: 400,
              height: '30px',
              overflow: 'hidden',
            }}>
            <div className="control has-icons-right">
              <Controller
                render={({ onChange, value }) => (
                  <NumberFormat
                    style={{ width: 150, border: 'none' }}
                    className="input is-small"
                    thousandSeparator
                    onValueChange={(v) => onChange(v.value)}
                    value={value}
                    placeholder={placeholder ? formatMessage({ id: placeholder }) : ''}
                  />
                )}
                name={name[0]}
                control={control}
                defaultValue={defaultValue?.[0]}
              />
              <span className="icon is-small is-right">
                <button className="delete is-small" onClick={() => setValue?.(name[0], '')} />
              </span>
            </div>
            <span className="icon is-small pl-1 pr-1">
              <i className="mdi mdi-arrow-right" aria-hidden="true" />
            </span>
            <div className="control has-icons-right">
              <Controller
                render={({ onChange, value }) => (
                  <NumberFormat
                    style={{ width: 150, border: 'none' }}
                    className="input is-small"
                    thousandSeparator
                    onValueChange={(v) => onChange(v.value)}
                    value={value}
                    // placeholder={formatMessage({ id: 'To' })}
                  />
                )}
                name={name[1]}
                control={control}
                defaultValue={defaultValue?.[1]}
              />
              <span className="icon is-small is-right">
                <button className="delete is-small" onClick={() => setValue?.(name[1], '')} />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
