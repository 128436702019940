import React, { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import { HTTMLEditorControl } from '../../../../../components/html-editor-control';
import { ModalUploadFile } from '../../../../../components/modal-upload-image';
import { LANGUAGE } from '../../../../../hook/use-lang';
import { useConfirm } from '../../../../../ms-core/hook/use-confirm';
import { DataSource } from '../../../../../ms-core/types/status-source-type';
import { showToast } from '../../../../../ms-core/utils/toast';
import { getLangUIConfig, getUIConfigById, updateUIConfigById } from '../../../../../services/common/website-builders';
import { formatHtml } from '../../../../../utils/helper';
import { Select } from '../../components/select';

const SuperVipContent = memo(() => {
  const [lang, setLang] = useState<string>(LANGUAGE.EN);
  const [languageTypeSource, setLanguageTypeSource] = useState<DataSource>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { control, handleSubmit, reset, watch, errors, register, setValue } = useForm({ shouldUnregister: false });
  const { showConfirm } = useConfirm();

  const changeDataFollowLang = async (lang: string) => {
    setLang(lang as LANGUAGE);
  };

  const fetchDataLang = () => {
    setIsLoading(true);

    getLangUIConfig()
      .then((resLang) => {
        const languageTypeSourceData: DataSource = {};
        resLang?.forEach((item: Record<string, any>) => {
          languageTypeSourceData[item?.language] = {
            label: item.name,
            value: item?.language,
            color: 'white',
          };
        });
        setLanguageTypeSource(languageTypeSourceData);
      })
      .finally(() => setIsLoading(false));
  };

  const fetchData = async (lang: string) => {
    setIsLoading(true);
    setTimeout(() => {
      getUIConfigById(lang, 'super-vip-content')
        .then((res) => {
          reset({
            superVip: {
              content: formatHtml(res?.superVip.content ?? ''),
            },
          });
        })
        .catch(() =>
          reset({
            superVip: {},
          }),
        )
        .finally(() => setIsLoading(false));
    }, 100);
  };

  const onSubmit = (values: Record<string, any>) => {
    updateUIConfigById(values, lang, 'super-vip-content').then(() => {
      showToast.success('Update Resource Success');
      fetchData(lang);
    });
  };

  const onConfirmSubmit = (values: Record<string, any>) => {
    showConfirm('You have changes made. Would you like to save current progress?', 'Accept', {
      onConfirm: () => onSubmit(values),
    });
  };

  useEffect(() => {
    fetchDataLang();
  }, []);

  useEffect(() => {
    if (!lang) return;

    fetchData(lang);
  }, [lang]);

  return (
    <div className={`static-content section`}>
      <div className="columns is-desktop">
        <div className="column">
          <div className="card has-height-medium">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="mdi mdi-alpha-v-circle" />
                  </span>
                  <span>
                    <FormattedMessage id="Super vip" />
                  </span>
                </p>
                <a className="card-header-icon">
                  <button className="is-small button is-ghost mr-0">
                    {!!languageTypeSource && (
                      <Select
                        onChangeData={changeDataFollowLang}
                        defaultValue={lang}
                        name="lang"
                        options={languageTypeSource}
                        disableAllValue
                      />
                    )}
                  </button>

                  <ModalUploadFile folderName="ui-config" isHasAccessPublic />

                  {isLoading ? (
                    <Skeleton count={1} />
                  ) : (
                    <button onClick={() => fetchData(lang)} type="button" className="button is-small">
                      <span className="icon">
                        <i className="mdi mdi-refresh default" />
                      </span>
                      <span>
                        <FormattedMessage id="Refresh data" />
                      </span>
                    </button>
                  )}
                </a>
              </header>

              <div className="card-content pt-0">
                <div className="content">
                  <div className="static-content">
                    <form onSubmit={handleSubmit(onConfirmSubmit)}>
                      <div className={`field p-5 is-border`}>
                        <div className="field-label is-normal has-text-left pb-4	">
                          <div className="label">
                            {/* {isLoading ? <Skeleton count={1} /> : <FormattedMessage id="Super vip" />} */}
                          </div>
                        </div>
                        <div className="field-body">
                          <div className="field">
                            <div className="control">
                              <div className="info-description">
                                <div className="field pt-2">
                                  <label className="label">
                                    <div className="level">
                                      <div className="level-left">
                                        <div className="level-item">
                                          {isLoading ? <Skeleton count={1} /> : <FormattedMessage id="Content" />}
                                        </div>
                                      </div>
                                    </div>
                                  </label>
                                  <div className="control">
                                    <>
                                      {isLoading ? (
                                        <Skeleton width="100%" height="65vh" count={1} />
                                      ) : (
                                        <>
                                          <HTTMLEditorControl
                                            defaultValue={watch('superVip.content')}
                                            rules={{
                                              required: {
                                                value: true,
                                                message: 'This field is required!',
                                              },
                                            }}
                                            name="superVip.content"
                                            control={control}
                                            width="100%"
                                            height="65vh"
                                          />
                                        </>
                                      )}
                                    </>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="field">
                        <div className="field-label is-normal" />
                        <div className="field-body">
                          <div className="field">
                            {isLoading ? (
                              <Skeleton count={1} />
                            ) : (
                              <button type="submit" onClick={onConfirmSubmit} className="button is-primary">
                                <span>
                                  <FormattedMessage id="Submit" />
                                </span>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default SuperVipContent;
