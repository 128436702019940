/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
/*eslint @typescript-eslint/no-unused-vars: 0 */
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { PaginationLimits } from '../../constants/types/pagination-limits';
import { Pagination } from '../models/pagination';
import './ms-pagination.scss';

enum PaginationType {
  OFFSET,
  PAGE,
}

export type Props = {
  onSearchChanged: (params: Record<string, unknown>) => void;
  pagination?: Pagination;
  paginationType?: PaginationType;
};

const MsPagination: React.FunctionComponent<Props> = (props) => {
  const curPage = props.pagination?.number || 1;
  const [pageInput, setPageInput] = useState(curPage);
  const { formatMessage } = useIntl();

  if (!props.pagination || isEmpty(props.pagination)) return <></>;

  const { totalPages, number, totalElements } = props.pagination ?? { totalPages: 1, number: 1, totalElements: 10 };
  const hasPrevPage = number > 1;
  const hasNextPage = number < totalPages;

  const handleSelectLimit = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    props.onSearchChanged({
      size: e.target.value,
    });
  };

  const onPageChanged = (page: number) => {
    props.onSearchChanged({
      page,
    });
  };

  return (
    <div className="level">
      <div className="level-left" />

      <div className="level-right">
        <div className="level-item mr-5">
          {totalElements != null && (
            <p className="is-size-7">
              <FormattedMessage id="Total" />
              &nbsp;
              {Intl.NumberFormat('en').format(totalElements)}
            </p>
          )}
        </div>

        <div className="level-item">
          <nav className="pagination is-centered is-small" role="navigation" aria-label="pagination">
            <div className="control has-icons-left">
              <div className="select is-small">
                <select value={props.pagination.size ?? 15} onChange={handleSelectLimit}>
                  <option value={''}>
                    -- {formatMessage({ id: 'Pagination' })}
                    --
                  </option>
                  {PaginationLimits.map((limit) => (
                    <option key={limit} value={limit}>
                      {limit}&nbsp;
                      {formatMessage({ id: 'records' })}
                    </option>
                  ))}
                </select>
              </div>
              <span className="icon is-small is-left">
                <i className="mdi mdi-label-multiple" />
              </span>
            </div>
            <button className="pagination-previous" onClick={() => onPageChanged(curPage - 1)} disabled={!hasPrevPage}>
              <FormattedMessage id="Previous" />
            </button>
            <button className="pagination-next" onClick={() => onPageChanged(curPage + 1)} disabled={!hasNextPage}>
              <FormattedMessage id="Next page" />
            </button>

            <ul className="pagination-list">
              {curPage > 2 ? (
                <li>
                  <button className="pagination-link" onClick={() => onPageChanged(1)}>
                    {1}
                  </button>
                </li>
              ) : (
                <></>
              )}
              {curPage > 3 ? (
                <li>
                  <span className="pagination-ellipsis">&hellip;</span>
                </li>
              ) : (
                <></>
              )}
              {hasPrevPage ? (
                <button className="pagination-link" onClick={() => onPageChanged(number - 1)}>
                  {number - 1}
                </button>
              ) : (
                <></>
              )}
              <li>
                <button className="pagination-link is-current" onClick={() => onPageChanged(curPage)}>
                  {curPage}
                </button>
              </li>
              {hasNextPage ? (
                <button className="pagination-link" onClick={() => onPageChanged(number + 1)}>
                  {number + 1}
                </button>
              ) : (
                <></>
              )}
              {curPage < totalPages - 2 ? (
                <li>
                  <span className="pagination-ellipsis">&hellip;</span>
                </li>
              ) : (
                <></>
              )}
              {curPage < totalPages - 1 ? (
                <li>
                  <button className="pagination-link" onClick={() => onPageChanged(totalPages)}>
                    {totalPages}
                  </button>
                </li>
              ) : (
                <></>
              )}
            </ul>
          </nav>
        </div>

        <div className="level-item MS_Pagination">
          <form
            onSubmit={(event) => {
              event.preventDefault();
              onPageChanged(pageInput);
            }}>
            <div className="field has-addons">
              <div className="control">
                <input
                  type="number"
                  placeholder={formatMessage({ id: 'Jump to page...' })}
                  className="input is-small"
                  value={pageInput}
                  onChange={(event) => setPageInput(Number(event.target.value))}
                />
              </div>
              <div className="control">
                <button type="submit" className="button is-small">
                  <FormattedMessage id="Go" />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MsPagination;
