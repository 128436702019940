import moment from 'moment';
import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import ShowIconImage from '../../../components/show-icon-image';
import { DateFormat, DatetimeFormat, TimeFormat, TimeWithZoneFormat } from '../../const/date-time';
import { DataType } from '../../models/ms-type';
import { DataSource } from '../../types/status-source-type';
import { stopPropagation } from '../../utils/stop-propagation';
import GGMoneyCell from '../gg-money-cell';
import Tag from '../tag';
import UserLink from '../user-link';
import { CopyIconRight } from '../../../components/copy-Icon';
import './index.scss';

interface Props {
  cellType: DataType;
  data: any;
  extData?: unknown;
  dataSource?: DataSource;
  rowSpan?: number;
  tdClassName?: string;
  translation?: boolean;
  currencyDecimal?: number;
  isCopy?: boolean;
}

function MsCell(props: Props) {
  const { translation = true } = props;
  let cell: ReactElement;

  switch (props.cellType) {
    case 'number':
      const number = parseInt(props.data as string);
      cell = (
        <td className="has-text-right">{isNaN(number) ? '---' : new Intl.NumberFormat().format(Number(props.data))}</td>
      );
      break;

    case 'time':
      if (typeof props.data === 'number') {
        cell = <td>{moment.unix(props.data).utcOffset(7).format(TimeFormat)}</td>;
      } else {
        cell = props.data ? <td>{moment.utc(props.data).utcOffset(7).format(TimeWithZoneFormat)}</td> : <td />;
      }
      break;

    case 'date':
      if (typeof props.data === 'number') {
        cell = <td>{moment.unix(props.data).utcOffset(7).format(DateFormat)}</td>;
      } else {
        cell = props.data ? <td>{moment.utc(props.data).utcOffset(7).format(DateFormat)}</td> : <td />;
      }
      break;

    case 'datetime':
      if (typeof props.data === 'number') {
        cell = <td>{moment.unix(props.data).utcOffset(7).format(DatetimeFormat)}</td>;
      } else {
        cell = props.data ? <td>{moment.utc(props.data).utcOffset(7).format(DatetimeFormat)}</td> : <td />;
      }
      break;

    case 'datetime2row':
      if (typeof props.data === 'number') {
        cell = (
          <td>
            <div>
              <span className="icon">
                <i className="mdi mdi-18px mdi-calendar-today" />
              </span>
              {moment.unix(props.data).utcOffset(7).format(DateFormat)}
            </div>
            <div>
              <span className="icon">
                <i className="mdi mdi-18px mdi-clock-outline" />
              </span>
              {moment.unix(props.data).utcOffset(7).format(TimeFormat)}
            </div>
          </td>
        );
      } else {
        cell = props.data ? (
          <td>
            <div>
              <span className="icon">
                <i className="mdi mdi-18px mdi-calendar-today" />
              </span>
              {moment.utc(props.data).utcOffset(7).format(DateFormat)}
            </div>
            <div>
              <span className="icon">
                <i className="mdi mdi-18px mdi-clock-outline" />
              </span>
              {moment.utc(props.data).utcOffset(7).format(TimeFormat)}
            </div>
          </td>
        ) : (
          <td />
        );
      }
      break;

    case 'email':
      cell = (
        <td>
          <a href={`mailto:${props.data}`} target="mailtostaff">
            {props.data}
          </a>
        </td>
      );
      break;

    case 'url':
      const { label, url } = props.data as { label: string; url: string };
      cell = (
        <td>
          <Link to={`${url}`} onClick={stopPropagation}>
            {label}
          </Link>
        </td>
      );
      break;

    case 'urlOpenNewWindows':
      const data = props.data as { label: string; url: string };
      cell = (
        <td>
          <a href={`#${data.url}`} style={{ marginTop: 4 }} target="_blank" rel="noreferrer">
            {data.label}
          </a>
        </td>
      );
      break;

    case 'bool':
      cell = <td>{props.data ? <i className="mdi mdi-checkbox-marked has-text-primary mdi-24px" /> : null}</td>;
      break;

    case 'money':
      // const money = parseInt(props.data as string);
      cell = <GGMoneyCell data={props.data as number} currencyDecimal={props.currencyDecimal} />;
      break;

    case 'tag':
      cell = (
        <td className="is-unset-max-width has-text-centered">
          {Array.isArray(props.data) ? (
            props.data.map((data, index) => (
              <Tag key={index} color={props.dataSource?.[data]?.color} className="mr-1">
                {props.dataSource?.[data]?.label ? (
                  translation ? (
                    props.dataSource?.[data]?.valueOfTranslation ? (
                      <FormattedMessage
                        id={props.dataSource?.[data]?.label}
                        values={props.dataSource?.[data]?.valueOfTranslation}
                      />
                    ) : (
                      <FormattedMessage id={props.dataSource?.[data]?.label} />
                    )
                  ) : (
                    props.dataSource?.[data]?.label
                  )
                ) : (
                  data
                )}
              </Tag>
            ))
          ) : !!props.data != null ? (
            <Tag color={props.dataSource?.[props.data]?.color}>
              {props.dataSource?.[props.data]?.label ? (
                translation ? (
                  props.dataSource?.[props.data]?.valueOfTranslation ? (
                    <FormattedMessage
                      id={props.dataSource?.[props.data]?.label}
                      values={props.dataSource?.[props.data]?.valueOfTranslation}
                    />
                  ) : (
                    <FormattedMessage id={props.dataSource?.[props.data]?.label} />
                  )
                ) : (
                  props.dataSource?.[props.data]?.label
                )
              ) : (
                props.data
              )}
            </Tag>
          ) : (
            ''
          )}
        </td>
      );
      break;

    case 'status':
      cell = (
        <td
          className={`${
            props.dataSource?.[props.data as string]?.color ? 'has-text-white' : ''
          } has-text-centered status-cell has-background-${props.dataSource?.[props.data as string]?.color}`}>
          {props.dataSource?.[props.data as string]?.label ? (
            <FormattedMessage id={props.dataSource?.[props.data as string]?.label} />
          ) : (
            props.data
          )}
        </td>
      );
      break;

    case 'select':
      cell = (
        <td
          className={`has-text-white has-text-centered has-background-${
            props.dataSource?.[props.data as string]?.color
          }`}>
          {props.dataSource?.[props.data as string]?.label ? (
            <FormattedMessage id={props.dataSource?.[props.data as string]?.label} />
          ) : (
            props.data
          )}
        </td>
      );
      break;

    case 'id':
      cell = (
        <td className="has-text-weight-bold is-overflow-visible">
          <div className="has-tooltip-arrow is-flex" data-tooltip={props.data}>
            {props.isCopy && (
              <>
                <CopyIconRight value={props?.data} /> &nbsp;
              </>
            )}
            <div className="is-text">{props.data}</div>
          </div>
        </td>
      );
      break;

    case 'player':
      cell = (
        <td rowSpan={props.rowSpan} className={`${props.tdClassName} is-overflow-visible`}>
          <div className="has-tooltip-arrow is-flex" data-tooltip={(props.data as string[])[1]}>
            {props?.data[1] && <CopyIconRight value={props?.data[1]} />}&nbsp;
            <UserLink className="is-text" id={(props.data as string[])[0]}>
              {(props.data as string[])[1]}{' '}
            </UserLink>
          </div>
        </td>
      );
      break;

    case 'imageServiceWithSuggested':
    case 'imageService':
    case 'image':
      const returnImage = () => {
        if (!props?.data || props?.data === '') return <td />;
        return (
          <td>
            <div className="is-flex is-justify-content-center has-text-centered has-text-weight-semibold">
              <ShowIconImage href={props?.data} className="is-24x24 is-inline-block" />
            </div>
          </td>
        );
      };

      cell = returnImage();
      break;

    case 'textMultiline':
      cell = (
        <td className="is-overflow-visible">
          <div
            className="has-tooltip-multiline"
            data-tooltip={Array.isArray(props.data) ? props.data.join(', ') : props.data}>
            <div className="is-text">{Array.isArray(props.data) ? props.data.join(', ') : props.data}</div>
          </div>
        </td>
      );
      break;

    default:
      cell = (
        <td className="is-overflow-visible">
          <div
            className="has-tooltip-arrow"
            data-tooltip={
              typeof props.data === 'object' ? '' : Array.isArray(props.data) ? props.data.join(', ') : props.data
            }>
            <div className="is-text">{Array.isArray(props.data) ? props.data.join(', ') : props.data}</div>
          </div>
        </td>
      );
      break;
  }

  return cell;
}

export default MsCell;
