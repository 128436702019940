/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from 'react';
import Select from 'react-select';
import { DataSource, IDataSourSelect, ValueOfTranslation } from '../../ms-core/types/status-source-type';
import { CSSObject } from '@emotion/serialize';
import { FormattedMessage, useIntl } from 'react-intl';
import { useController } from 'react-hook-form';
import { debounce, keys } from 'lodash';
// import { useFormContext } from 'react-hook-form';

type CFC = React.FC<
  Parameters<typeof useController>[0] & {
    label?: string;
    name: string;
    options: DataSource;
    defaultValue?: string | number | boolean | unknown;
    placeholder?: string;
    className?: string;
    errors?: boolean;
    handleChange?: (e: string) => void;
    eventInput?: (e: string) => void;
    disabled?: boolean;
    isClearable?: boolean;
    style?: React.CSSProperties;
    errorsMessage?: string;
    notTranslation?: boolean;
    valueOfTranslationPlaceholder?: ValueOfTranslation;
  }
>;

const CustomSelect: CFC = ({
  options,
  name,
  control,
  rules,
  placeholder,
  defaultValue,
  label,
  errors,
  handleChange,
  eventInput,
  disabled,
  isClearable,
  style,
  errorsMessage,
  notTranslation = false,
  className,
  valueOfTranslationPlaceholder,
}) => {
  const { formatMessage } = useIntl();

  const customStylesNormal = {
    valueContainer: (provided: CSSObject): CSSObject => ({
      ...provided,
      padding: '0 6px',
    }),
    option: (provided: CSSObject): CSSObject => ({
      ...provided,
      padding: '8px 6px',
    }),
    control: (base: CSSObject) => ({
      ...base,
      borderColor: errors ? 'red' : 'hsl(0,0%,80%)',
      boxShadow: errors ? 'red' : 'hsl(0,0%,80%)',
    }),
  };
  const {
    field: { onChange },
  } = useController({ name, rules, control });
  const convertOption = (options: DataSource): IDataSourSelect[] => {
    const data: IDataSourSelect[] = [
      // {
      //   label: placeholder ? formatMessage({ id: placeholder }) : '',
      //   value: '',
      // },
    ];
    options &&
      keys(options).forEach((item: any) =>
        data.push({
          label:
            item && !notTranslation
              ? options?.[item]?.valueOfTranslation
                ? formatMessage({ id: options?.[item].label }, options?.[item]?.valueOfTranslation)
                : formatMessage({ id: options?.[item].label })
              : options?.[item].label,
          value: options?.[item].value,
        }),
      );
    return data;
  };
  const search = useRef(
    debounce((value) => {
      eventInput?.(value);
    }, 300),
  );
  const handleOnchange = (value: string) => {
    search.current(value);
  };
  return (
    <div className={`${className ? className : ''} selection-control `} style={style}>
      {label && (
        <label className="label">
          {label} <b className="has-text-danger">*</b>
        </label>
      )}
      <div className={`field`}>
        <Select
          className="is-fullwidth"
          defaultValue={convertOption(options).filter((option) => `${option.value}` === `${defaultValue}`)}
          onChange={(option) => {
            onChange(option?.value);
            handleChange?.(option?.value as string);
          }}
          onInputChange={(option) => {
            handleOnchange(option);
          }}
          options={convertOption(options)}
          placeholder={
            placeholder && !notTranslation
              ? valueOfTranslationPlaceholder
                ? formatMessage({ id: placeholder }, valueOfTranslationPlaceholder)
                : formatMessage({ id: placeholder })
              : placeholder
          }
          styles={customStylesNormal as any}
          menuPosition={'fixed'}
          isDisabled={disabled}
          isClearable={isClearable}
        />
        {errorsMessage && <p className="help has-text-danger">{<FormattedMessage id={errorsMessage} />}</p>}
      </div>
    </div>
  );
};

export default CustomSelect;
